// Code generated by scripts/pathpida.mjs DO NOT EDIT
// eslint-disable

export const staticPath__well_known_security_txt = "/.well-known/security.txt";
export const staticPath_apple_touch_icon_png = "/apple-touch-icon.png";
export const staticPath_assets_ads_criteo_co1_png = "/assets/ads/criteo/co1.png";
export const staticPath_assets_ads_criteo_co2_png = "/assets/ads/criteo/co2.png";
export const staticPath_assets_ads_criteo_co3_png = "/assets/ads/criteo/co3.png";
export const staticPath_assets_ads_criteo_coupon_png = "/assets/ads/criteo/coupon.png";
export const staticPath_assets_ads_criteo_coupon_logo_png = "/assets/ads/criteo/coupon_logo.png";
export const staticPath_assets_ads_criteo_extra_badge_0_png = "/assets/ads/criteo/extra_badge_0.png";
export const staticPath_assets_ads_criteo_extra_badge_1_png = "/assets/ads/criteo/extra_badge_1.png";
export const staticPath_assets_ads_criteo_extra_badge_2_png = "/assets/ads/criteo/extra_badge_2.png";
export const staticPath_assets_ads_criteo_sm1_png = "/assets/ads/criteo/sm1.png";
export const staticPath_assets_ads_criteo_sm2_png = "/assets/ads/criteo/sm2.png";
export const staticPath_assets_ads_criteo_sm3_png = "/assets/ads/criteo/sm3.png";
export const staticPath_assets_ads_criteo_sm4_png = "/assets/ads/criteo/sm4.png";
export const staticPath_assets_advisers_izumida_jpg = "/assets/advisers/izumida.jpg";
export const staticPath_assets_affiliate_modal_moneiroconcierge_svg = "/assets/affiliate_modal/moneiroconcierge.svg";
export const staticPath_assets_affiliate_modal_seminar_svg = "/assets/affiliate_modal/seminar.svg";
export const staticPath_assets_affiliate_modal_shindan_svg = "/assets/affiliate_modal/shindan.svg";
export const staticPath_assets_after_seminar_appeal_tab_1_svg = "/assets/after-seminar/appeal-tab-1.svg";
export const staticPath_assets_after_seminar_appeal_tab_2_svg = "/assets/after-seminar/appeal-tab-2.svg";
export const staticPath_assets_after_seminar_appeal_tab_3_svg = "/assets/after-seminar/appeal-tab-3.svg";
export const staticPath_assets_after_seminar_button_bg_phone_svg = "/assets/after-seminar/button-bg-phone.svg";
export const staticPath_assets_after_seminar_message_icon_png = "/assets/after-seminar/message-icon.png";
export const staticPath_assets_after_seminar_mortgage_arrow_down_png = "/assets/after-seminar/mortgage/arrow-down.png";
export const staticPath_assets_after_seminar_mortgage_balloon_in_float_banner_svg = "/assets/after-seminar/mortgage/balloon-in-float-banner.svg";
export const staticPath_assets_after_seminar_mortgage_man_in_rest_svg = "/assets/after-seminar/mortgage/man-in-rest.svg";
export const staticPath_assets_after_seminar_mortgage_man_moyamoya_svg = "/assets/after-seminar/mortgage/man-moyamoya.svg";
export const staticPath_assets_after_seminar_mortgage_mogereco_logo_subtitle_png = "/assets/after-seminar/mortgage/mogereco_logo_subtitle.png";
export const staticPath_assets_after_seminar_mortgage_subtitle_underline_png = "/assets/after-seminar/mortgage/subtitle_underline.png";
export const staticPath_assets_after_seminar_mortgage_woman_have_trouble_base_png = "/assets/after-seminar/mortgage/woman-have-trouble-base.png";
export const staticPath_assets_after_seminar_mortgage_woman_have_trouble_md_png = "/assets/after-seminar/mortgage/woman-have-trouble-md.png";
export const staticPath_assets_archivements_cover1_jpg = "/assets/archivements/cover1.jpg";
export const staticPath_assets_archivements_cover2_jpg = "/assets/archivements/cover2.jpg";
export const staticPath_assets_archivements_cover3_jpg = "/assets/archivements/cover3.jpg";
export const staticPath_assets_archivements_cover4_jpg = "/assets/archivements/cover4.jpg";
export const staticPath_assets_archivements_cover5_jpg = "/assets/archivements/cover5.jpg";
export const staticPath_assets_archivements_logo_jpg = "/assets/archivements/logo.jpg";
export const staticPath_assets_archivements_manekai_by_ameba_jpg = "/assets/archivements/manekai-by-ameba.jpg";
export const staticPath_assets_auth_login_png = "/assets/auth/login.png";
export const staticPath_assets_auth_mail_action_png = "/assets/auth/mail-action.png";
export const staticPath_assets_auth_mail_png = "/assets/auth/mail.png";
export const staticPath_assets_bimi_moneiro_svg = "/assets/bimi/moneiro.svg";
export const staticPath_assets_books_cover1_png = "/assets/books/cover1.png";
export const staticPath_assets_books_cover2_jpg = "/assets/books/cover2.jpg";
export const staticPath_assets_books_cover3_jpg = "/assets/books/cover3.jpg";
export const staticPath_assets_books_cover4_jpg = "/assets/books/cover4.jpg";
export const staticPath_assets_books_cover5_jpg = "/assets/books/cover5.jpg";
export const staticPath_assets_case_arrow_down_black_png = "/assets/case/arrow-down-black.png";
export const staticPath_assets_case_case_01_after_graph_png = "/assets/case/case-01-after-graph.png";
export const staticPath_assets_case_case_01_before_graph_png = "/assets/case/case-01-before-graph.png";
export const staticPath_assets_case_case_01_png = "/assets/case/case-01.png";
export const staticPath_assets_case_case_02_after_graph_png = "/assets/case/case-02-after-graph.png";
export const staticPath_assets_case_case_02_before_graph_png = "/assets/case/case-02-before-graph.png";
export const staticPath_assets_case_case_02_png = "/assets/case/case-02.png";
export const staticPath_assets_case_case_03_after_graph_png = "/assets/case/case-03-after-graph.png";
export const staticPath_assets_case_case_03_before_graph_png = "/assets/case/case-03-before-graph.png";
export const staticPath_assets_case_case_03_png = "/assets/case/case-03.png";
export const staticPath_assets_common_paginate_first_page_icon_svg = "/assets/common/paginate/first-page-icon.svg";
export const staticPath_assets_common_paginate_last_page_icon_svg = "/assets/common/paginate/last-page-icon.svg";
export const staticPath_assets_common_paginate_next_page_icon_svg = "/assets/common/paginate/next-page-icon.svg";
export const staticPath_assets_common_paginate_prev_page_icon_svg = "/assets/common/paginate/prev-page-icon.svg";
export const staticPath_assets_concier_widgetHeader_png = "/assets/concier/widgetHeader.png";
export const staticPath_assets_consulting_calendar_prefer_female_disabled_pc_png = "/assets/consulting/calendar/prefer-female-disabled-pc.png";
export const staticPath_assets_consulting_calendar_prefer_female_disabled_png = "/assets/consulting/calendar/prefer-female-disabled.png";
export const staticPath_assets_consulting_calendar_prefer_female_pc_png = "/assets/consulting/calendar/prefer-female-pc.png";
export const staticPath_assets_consulting_calendar_prefer_female_png = "/assets/consulting/calendar/prefer-female.png";
export const staticPath_assets_consulting_calendar_prefer_male_disabled_pc_png = "/assets/consulting/calendar/prefer-male-disabled-pc.png";
export const staticPath_assets_consulting_calendar_prefer_male_disabled_png = "/assets/consulting/calendar/prefer-male-disabled.png";
export const staticPath_assets_consulting_calendar_prefer_male_pc_png = "/assets/consulting/calendar/prefer-male-pc.png";
export const staticPath_assets_consulting_calendar_prefer_male_png = "/assets/consulting/calendar/prefer-male.png";
export const staticPath_assets_consulting_cases_edu_circlegraph_after_base_png = "/assets/consulting/cases/edu-circlegraph-after-base.png";
export const staticPath_assets_consulting_cases_edu_circlegraph_after_md_png = "/assets/consulting/cases/edu-circlegraph-after-md.png";
export const staticPath_assets_consulting_cases_edu_circlegraph_before_base_png = "/assets/consulting/cases/edu-circlegraph-before-base.png";
export const staticPath_assets_consulting_cases_edu_circlegraph_before_md_png = "/assets/consulting/cases/edu-circlegraph-before-md.png";
export const staticPath_assets_consulting_cases_future_circlegraph_after_png = "/assets/consulting/cases/future-circlegraph-after.png";
export const staticPath_assets_consulting_cases_future_circlegraph_before_png = "/assets/consulting/cases/future-circlegraph-before.png";
export const staticPath_assets_consulting_cases_hoken_bar_graph_after_png = "/assets/consulting/cases/hoken-bar-graph-after.png";
export const staticPath_assets_consulting_cases_hoken_bar_graph_before_png = "/assets/consulting/cases/hoken-bar-graph-before.png";
export const staticPath_assets_consulting_cases_ideco_circlegraph_after_base_png = "/assets/consulting/cases/ideco-circlegraph-after-base.png";
export const staticPath_assets_consulting_cases_ideco_circlegraph_after_md_png = "/assets/consulting/cases/ideco-circlegraph-after-md.png";
export const staticPath_assets_consulting_cases_ideco_circlegraph_before_png = "/assets/consulting/cases/ideco-circlegraph-before.png";
export const staticPath_assets_consulting_cases_insurance_bargraph_after_png = "/assets/consulting/cases/insurance-bargraph-after.png";
export const staticPath_assets_consulting_cases_insurance_bargraph_before_png = "/assets/consulting/cases/insurance-bargraph-before.png";
export const staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_base_png = "/assets/consulting/cases/mutual-funds-circlegraph-after-base.png";
export const staticPath_assets_consulting_cases_mutual_funds_circlegraph_after_md_png = "/assets/consulting/cases/mutual-funds-circlegraph-after-md.png";
export const staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_base_png = "/assets/consulting/cases/mutual-funds-circlegraph-before-base.png";
export const staticPath_assets_consulting_cases_mutual_funds_circlegraph_before_md_png = "/assets/consulting/cases/mutual-funds-circlegraph-before-md.png";
export const staticPath_assets_consulting_cases_nisa_circlegraph_after_png = "/assets/consulting/cases/nisa-circlegraph-after.png";
export const staticPath_assets_consulting_cases_nisa_circlegraph_before_png = "/assets/consulting/cases/nisa-circlegraph-before.png";
export const staticPath_assets_consulting_concierge_kv_illust_svg = "/assets/consulting/concierge/kv_illust.svg";
export const staticPath_assets_consulting_concierge_oota_wav = "/assets/consulting/concierge/oota.wav";
export const staticPath_assets_consulting_concierge_shinagawa_wav = "/assets/consulting/concierge/shinagawa.wav";
export const staticPath_assets_consulting_investment_courses_arrow_down_png = "/assets/consulting/investment_courses/arrow-down.png";
export const staticPath_assets_consulting_investment_courses_arrow_right_png = "/assets/consulting/investment_courses/arrow-right.png";
export const staticPath_assets_consulting_sbi_cards_svg = "/assets/consulting/sbi/cards.svg";
export const staticPath_assets_consulting_top_hero_base_png = "/assets/consulting/top/hero-base.png";
export const staticPath_assets_consulting_top_hero_md_png = "/assets/consulting/top/hero-md.png";
export const staticPath_assets_consulting_top_ogp_png = "/assets/consulting/top/ogp.png";
export const staticPath_assets_dash_marker_png = "/assets/dash-marker.png";
export const staticPath_assets_decoration_filled_star_svg = "/assets/decoration/filled-star.svg";
export const staticPath_assets_decoration_non_filled_star_svg = "/assets/decoration/non-filled-star.svg";
export const staticPath_assets_docs_discontinue_yahoo_login_step02_png = "/assets/docs/discontinue-yahoo-login/step02.png";
export const staticPath_assets_docs_discontinue_yahoo_login_step03_png = "/assets/docs/discontinue-yahoo-login/step03.png";
export const staticPath_assets_docs_discontinue_yahoo_login_step04_png = "/assets/docs/discontinue-yahoo-login/step04.png";
export const staticPath_assets_docs_how_to_meeting_ios_step_1_png = "/assets/docs/how-to-meeting/ios-step-1.png";
export const staticPath_assets_docs_how_to_meeting_ios_step_2_png = "/assets/docs/how-to-meeting/ios-step-2.png";
export const staticPath_assets_docs_how_to_meeting_mail_png = "/assets/docs/how-to-meeting/mail.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_1_png = "/assets/docs/how-to-meeting/pc-step-1.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_2_png = "/assets/docs/how-to-meeting/pc-step-2.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_3_png = "/assets/docs/how-to-meeting/pc-step-3.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_4_png = "/assets/docs/how-to-meeting/pc-step-4.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_5_png = "/assets/docs/how-to-meeting/pc-step-5.png";
export const staticPath_assets_docs_how_to_meeting_pc_step_6_png = "/assets/docs/how-to-meeting/pc-step-6.png";
export const staticPath_assets_dot_marker_png = "/assets/dot-marker.png";
export const staticPath_assets_feature_1118aa_consulting_case_pc_1_png = "/assets/feature/1118aa/consulting-case-pc-1.png";
export const staticPath_assets_feature_1118aa_consulting_case_pc_2_png = "/assets/feature/1118aa/consulting-case-pc-2.png";
export const staticPath_assets_feature_1118aa_consulting_case_pc_3_png = "/assets/feature/1118aa/consulting-case-pc-3.png";
export const staticPath_assets_feature_1118aa_consulting_case_sp_1_png = "/assets/feature/1118aa/consulting-case-sp-1.png";
export const staticPath_assets_feature_1118aa_consulting_case_sp_2_png = "/assets/feature/1118aa/consulting-case-sp-2.png";
export const staticPath_assets_feature_1118aa_consulting_case_sp_3_png = "/assets/feature/1118aa/consulting-case-sp-3.png";
export const staticPath_assets_feature_1118aa_ogp_png = "/assets/feature/1118aa/ogp.png";
export const staticPath_assets_feature_1118aa_woman_sagasu_png = "/assets/feature/1118aa/woman-sagasu.png";
export const staticPath_assets_feature_207aa_concier_upper_body_with_white_png = "/assets/feature/207aa/concier-upper-body-with-white.png";
export const staticPath_assets_feature_207aa_hero_base_jpg = "/assets/feature/207aa/hero-base.jpg";
export const staticPath_assets_feature_207aa_hero_md_jpg = "/assets/feature/207aa/hero-md.jpg";
export const staticPath_assets_feature_207aa_ogp_png = "/assets/feature/207aa/ogp.png";
export const staticPath_assets_feature_207aa_participants_graph_png = "/assets/feature/207aa/participants-graph.png";
export const staticPath_assets_feature_207aa_reason_1_png = "/assets/feature/207aa/reason-1.png";
export const staticPath_assets_feature_207aa_reason_2_png = "/assets/feature/207aa/reason-2.png";
export const staticPath_assets_feature_207aa_reason_3_png = "/assets/feature/207aa/reason-3.png";
export const staticPath_assets_feature_207aa_wc_sagasu_svg = "/assets/feature/207aa/wc-sagasu.svg";
export const staticPath_assets_feature_207aa_woman_hikaku_svg = "/assets/feature/207aa/woman-hikaku.svg";
export const staticPath_assets_feature_207aa_woman_shindan_start_with_white_png = "/assets/feature/207aa/woman-shindan-start-with-white.png";
export const staticPath_assets_feature_207aa_woman_wakaranai_svg = "/assets/feature/207aa/woman-wakaranai.svg";
export const staticPath_assets_feature_207ab_hero_base_jpg = "/assets/feature/207ab/hero-base.jpg";
export const staticPath_assets_feature_207ac_hero_base_jpg = "/assets/feature/207ac/hero-base.jpg";
export const staticPath_assets_feature_207ac_hero_md_jpg = "/assets/feature/207ac/hero-md.jpg";
export const staticPath_assets_feature_207ac_ogp_jpg = "/assets/feature/207ac/ogp.jpg";
export const staticPath_assets_feature_207ac_reason_3_png = "/assets/feature/207ac/reason-3.png";
export const staticPath_assets_feature_207ad_ideco_bank_account_svg = "/assets/feature/207ad-ideco/bank-account.svg";
export const staticPath_assets_feature_207ad_ideco_hand_with_ideco_png = "/assets/feature/207ad-ideco/hand-with-ideco.png";
export const staticPath_assets_feature_207ad_ideco_hero_base_jpg = "/assets/feature/207ad-ideco/hero-base.jpg";
export const staticPath_assets_feature_207ad_ideco_hero_md_jpg = "/assets/feature/207ad-ideco/hero-md.jpg";
export const staticPath_assets_feature_207ad_ideco_idecotte_png = "/assets/feature/207ad-ideco/idecotte.png";
export const staticPath_assets_feature_207ad_ideco_illust_denkyu_svg = "/assets/feature/207ad-ideco/illust-denkyu.svg";
export const staticPath_assets_feature_207ad_ideco_money_over_hand_svg = "/assets/feature/207ad-ideco/money-over-hand.svg";
export const staticPath_assets_feature_207ad_ideco_money_svg = "/assets/feature/207ad-ideco/money.svg";
export const staticPath_assets_feature_207ad_ideco_ogp_jpg = "/assets/feature/207ad-ideco/ogp.jpg";
export const staticPath_assets_feature_207ad_ideco_pc_with_dollar_svg = "/assets/feature/207ad-ideco/pc-with-dollar.svg";
export const staticPath_assets_feature_207ad_ideco_point1_png = "/assets/feature/207ad-ideco/point1.png";
export const staticPath_assets_feature_207ad_ideco_point3_png = "/assets/feature/207ad-ideco/point3.png";
export const staticPath_assets_feature_208aa_concier_upper_body_with_white_png = "/assets/feature/208aa/concier-upper-body-with-white.png";
export const staticPath_assets_feature_208aa_hero_base_png = "/assets/feature/208aa/hero-base.png";
export const staticPath_assets_feature_208aa_hero_md_png = "/assets/feature/208aa/hero-md.png";
export const staticPath_assets_feature_208aa_ogp_png = "/assets/feature/208aa/ogp.png";
export const staticPath_assets_feature_208aa_participants_graph_png = "/assets/feature/208aa/participants-graph.png";
export const staticPath_assets_feature_208aa_reason_1_png = "/assets/feature/208aa/reason-1.png";
export const staticPath_assets_feature_208aa_reason_2_png = "/assets/feature/208aa/reason-2.png";
export const staticPath_assets_feature_208aa_reason_3_png = "/assets/feature/208aa/reason-3.png";
export const staticPath_assets_feature_208aa_wc_sagasu_svg = "/assets/feature/208aa/wc-sagasu.svg";
export const staticPath_assets_feature_208aa_woman_hikaku_svg = "/assets/feature/208aa/woman-hikaku.svg";
export const staticPath_assets_feature_208aa_woman_shindan_start_with_white_png = "/assets/feature/208aa/woman-shindan-start-with-white.png";
export const staticPath_assets_feature_208aa_woman_wakaranai_svg = "/assets/feature/208aa/woman-wakaranai.svg";
export const staticPath_assets_feature_422aa_guide_image_png = "/assets/feature/422aa/guide-image.png";
export const staticPath_assets_feature_422aa_hero_base_png = "/assets/feature/422aa/hero-base.png";
export const staticPath_assets_feature_422aa_hero_md_png = "/assets/feature/422aa/hero-md.png";
export const staticPath_assets_feature_422aa_know01_png = "/assets/feature/422aa/know01.png";
export const staticPath_assets_feature_422aa_know02_png = "/assets/feature/422aa/know02.png";
export const staticPath_assets_feature_422aa_know03_png = "/assets/feature/422aa/know03.png";
export const staticPath_assets_feature_422aa_ogp_png = "/assets/feature/422aa/ogp.png";
export const staticPath_assets_feature_425aa_bonds_about_moneiro_1_png = "/assets/feature/425aa-bonds/about-moneiro-1.png";
export const staticPath_assets_feature_425aa_bonds_about_moneiro_2_png = "/assets/feature/425aa-bonds/about-moneiro-2.png";
export const staticPath_assets_feature_425aa_bonds_about_moneiro_3_png = "/assets/feature/425aa-bonds/about-moneiro-3.png";
export const staticPath_assets_feature_425aa_bonds_about_moneiro_4_png = "/assets/feature/425aa-bonds/about-moneiro-4.png";
export const staticPath_assets_feature_425aa_bonds_advice_png = "/assets/feature/425aa-bonds/advice.png";
export const staticPath_assets_feature_425aa_bonds_bean_light_bulb_png = "/assets/feature/425aa-bonds/bean-light-bulb.png";
export const staticPath_assets_feature_425aa_bonds_bill_png = "/assets/feature/425aa-bonds/bill.png";
export const staticPath_assets_feature_425aa_bonds_bundle_of_bills_png = "/assets/feature/425aa-bonds/bundle-of-bills.png";
export const staticPath_assets_feature_425aa_bonds_hero_base_png = "/assets/feature/425aa-bonds/hero-base.png";
export const staticPath_assets_feature_425aa_bonds_hero_md_png = "/assets/feature/425aa-bonds/hero-md.png";
export const staticPath_assets_feature_425aa_bonds_magnifying_glass_png = "/assets/feature/425aa-bonds/magnifying-glass.png";
export const staticPath_assets_feature_425aa_bonds_mv_female_png = "/assets/feature/425aa-bonds/mv-female.png";
export const staticPath_assets_feature_425aa_bonds_ogp_png = "/assets/feature/425aa-bonds/ogp.png";
export const staticPath_assets_feature_425aa_bonds_review_user_1_png = "/assets/feature/425aa-bonds/review-user-1.png";
export const staticPath_assets_feature_425aa_bonds_review_user_2_png = "/assets/feature/425aa-bonds/review-user-2.png";
export const staticPath_assets_feature_425aa_bonds_review_user_3_png = "/assets/feature/425aa-bonds/review-user-3.png";
export const staticPath_assets_feature_425aa_bonds_step1_png = "/assets/feature/425aa-bonds/step1.png";
export const staticPath_assets_feature_425aa_bonds_step2_png = "/assets/feature/425aa-bonds/step2.png";
export const staticPath_assets_feature_425aa_bonds_step3_png = "/assets/feature/425aa-bonds/step3.png";
export const staticPath_assets_feature_425aa_bonds_worries_1_png = "/assets/feature/425aa-bonds/worries-1.png";
export const staticPath_assets_feature_425aa_bonds_worries_2_png = "/assets/feature/425aa-bonds/worries-2.png";
export const staticPath_assets_feature_425aa_bonds_worries_3_png = "/assets/feature/425aa-bonds/worries-3.png";
export const staticPath_assets_feature_425ab_bonds_advice_png = "/assets/feature/425ab-bonds/advice.png";
export const staticPath_assets_feature_425ab_bonds_step_1_png = "/assets/feature/425ab-bonds/step-1.png";
export const staticPath_assets_feature_425ab_bonds_step_2_png = "/assets/feature/425ab-bonds/step-2.png";
export const staticPath_assets_feature_425ab_bonds_step_3_png = "/assets/feature/425ab-bonds/step-3.png";
export const staticPath_assets_feature_425ab_bonds_worries_1_png = "/assets/feature/425ab-bonds/worries-1.png";
export const staticPath_assets_feature_425ab_bonds_worries_2_png = "/assets/feature/425ab-bonds/worries-2.png";
export const staticPath_assets_feature_425ab_bonds_worries_3_png = "/assets/feature/425ab-bonds/worries-3.png";
export const staticPath_assets_feature_425ac_bonds_hero_base_png = "/assets/feature/425ac-bonds/hero-base.png";
export const staticPath_assets_feature_425ac_bonds_hero_md_png = "/assets/feature/425ac-bonds/hero-md.png";
export const staticPath_assets_feature_425ac_bonds_ogp_png = "/assets/feature/425ac-bonds/ogp.png";
export const staticPath_assets_feature_606aa_hero_base_png = "/assets/feature/606aa/hero-base.png";
export const staticPath_assets_feature_606aa_hero_md_png = "/assets/feature/606aa/hero-md.png";
export const staticPath_assets_feature_606aa_ogp_jpg = "/assets/feature/606aa/ogp.jpg";
export const staticPath_assets_feature_628aa_insurance_hero_md_png = "/assets/feature/628aa-insurance/hero-md.png";
export const staticPath_assets_feature_628aa_insurance_ogp_png = "/assets/feature/628aa-insurance/ogp.png";
export const staticPath_assets_feature_628aa_insurance_points_how_to_choose_point_01_png = "/assets/feature/628aa-insurance/points/how-to-choose/point-01.png";
export const staticPath_assets_feature_628aa_insurance_points_how_to_choose_point_02_png = "/assets/feature/628aa-insurance/points/how-to-choose/point-02.png";
export const staticPath_assets_feature_628aa_insurance_points_how_to_choose_point_03_png = "/assets/feature/628aa-insurance/points/how-to-choose/point-03.png";
export const staticPath_assets_feature_628aa_insurance_points_moneiro_strength_point_01_png = "/assets/feature/628aa-insurance/points/moneiro-strength/point-01.png";
export const staticPath_assets_feature_628aa_insurance_points_moneiro_strength_point_02_png = "/assets/feature/628aa-insurance/points/moneiro-strength/point-02.png";
export const staticPath_assets_feature_628aa_insurance_points_moneiro_strength_point_03_png = "/assets/feature/628aa-insurance/points/moneiro-strength/point-03.png";
export const staticPath_assets_feature_628ba_insurance_ogp_png = "/assets/feature/628ba-insurance/ogp.png";
export const staticPath_assets_feature_628ba_insurance_points_how_to_choose_point_02_png = "/assets/feature/628ba-insurance/points/how-to-choose/point-02.png";
export const staticPath_assets_feature_628ca_insurance_ogp_png = "/assets/feature/628ca-insurance/ogp.png";
export const staticPath_assets_feature_628ca_insurance_points_how_to_choose_point_02_png = "/assets/feature/628ca-insurance/points/how-to-choose/point-02.png";
export const staticPath_assets_feature_628da_insurance_ogp_png = "/assets/feature/628da-insurance/ogp.png";
export const staticPath_assets_feature_628da_insurance_points_how_to_choose_point_02_png = "/assets/feature/628da-insurance/points/how-to-choose/point-02.png";
export const staticPath_assets_feature_628ea_insurance_ogp_png = "/assets/feature/628ea-insurance/ogp.png";
export const staticPath_assets_feature_628ea_insurance_points_how_to_choose_point_02_png = "/assets/feature/628ea-insurance/points/how-to-choose/point-02.png";
export const staticPath_assets_feature_fa_asset_icon_png = "/assets/feature/fa/asset-icon.png";
export const staticPath_assets_feature_fa_bank_icon_png = "/assets/feature/fa/bank-icon.png";
export const staticPath_assets_feature_fa_beginner_icon_png = "/assets/feature/fa/beginner-icon.png";
export const staticPath_assets_feature_fa_cap_icon_png = "/assets/feature/fa/cap-icon.png";
export const staticPath_assets_feature_fa_clip_png = "/assets/feature/fa/clip.png";
export const staticPath_assets_feature_fa_glass_icon_png = "/assets/feature/fa/glass-icon.png";
export const staticPath_assets_feature_fa_line_sp_png = "/assets/feature/fa/line-sp.png";
export const staticPath_assets_feature_fa_line_png = "/assets/feature/fa/line.png";
export const staticPath_assets_feature_fa_nishimura_hero_base_png = "/assets/feature/fa/nishimura/hero-base.png";
export const staticPath_assets_feature_fa_nishimura_hero_md_png = "/assets/feature/fa/nishimura/hero-md.png";
export const staticPath_assets_feature_fa_nishimura_nishimura_png = "/assets/feature/fa/nishimura/nishimura.png";
export const staticPath_assets_feature_fa_note_icon_png = "/assets/feature/fa/note-icon.png";
export const staticPath_assets_feature_fa_og_png = "/assets/feature/fa/og.png";
export const staticPath_assets_feature_fa_ogino_hero_base_png = "/assets/feature/fa/ogino/hero-base.png";
export const staticPath_assets_feature_fa_ogino_hero_md_png = "/assets/feature/fa/ogino/hero-md.png";
export const staticPath_assets_feature_fa_ogino_ogino_png = "/assets/feature/fa/ogino/ogino.png";
export const staticPath_assets_feature_fa_sasamura_hero_base_png = "/assets/feature/fa/sasamura/hero-base.png";
export const staticPath_assets_feature_fa_sasamura_hero_md_png = "/assets/feature/fa/sasamura/hero-md.png";
export const staticPath_assets_feature_fa_sasamura_sasamura_png = "/assets/feature/fa/sasamura/sasamura.png";
export const staticPath_assets_feature_fa_slash_left_png = "/assets/feature/fa/slash-left.png";
export const staticPath_assets_feature_fa_slash_right_png = "/assets/feature/fa/slash-right.png";
export const staticPath_assets_feature_fa_step01_png = "/assets/feature/fa/step01.png";
export const staticPath_assets_feature_fa_step02_png = "/assets/feature/fa/step02.png";
export const staticPath_assets_feature_fa_step03_png = "/assets/feature/fa/step03.png";
export const staticPath_assets_feature_fa_sugahara_hero_base_png = "/assets/feature/fa/sugahara/hero-base.png";
export const staticPath_assets_feature_fa_sugahara_hero_md_png = "/assets/feature/fa/sugahara/hero-md.png";
export const staticPath_assets_feature_fa_sugahara_sugahara_png = "/assets/feature/fa/sugahara/sugahara.png";
export const staticPath_assets_feature_fa_thumbnail_png = "/assets/feature/fa/thumbnail.png";
export const staticPath_assets_feature_fa_ueda_hero_base_png = "/assets/feature/fa/ueda/hero-base.png";
export const staticPath_assets_feature_fa_ueda_hero_md_png = "/assets/feature/fa/ueda/hero-md.png";
export const staticPath_assets_feature_fa_ueda_ueda_png = "/assets/feature/fa/ueda/ueda.png";
export const staticPath_assets_forms_concier_doc2x_png = "/assets/forms/concier-doc2x.png";
export const staticPath_assets_illust_3min_result_illust_lifeplan_png = "/assets/illust/3min-result-illust-lifeplan.png";
export const staticPath_assets_illust_3min_result_illust_look_over_png = "/assets/illust/3min-result-illust-look-over.png";
export const staticPath_assets_illust_3min_result_illust_preparation_png = "/assets/illust/3min-result-illust-preparation.png";
export const staticPath_assets_illust_applying_svg = "/assets/illust/applying.svg";
export const staticPath_assets_illust_asuka_svg = "/assets/illust/asuka.svg";
export const staticPath_assets_illust_calendar_svg = "/assets/illust/calendar.svg";
export const staticPath_assets_illust_commentary_png = "/assets/illust/commentary.png";
export const staticPath_assets_illust_concier_3_png = "/assets/illust/concier-3.png";
export const staticPath_assets_illust_concier_upper_body_2_svg = "/assets/illust/concier-upper-body-2.svg";
export const staticPath_assets_illust_concier_upper_body_svg = "/assets/illust/concier-upper-body.svg";
export const staticPath_assets_illust_concierge_allok_svg = "/assets/illust/concierge-allok.svg";
export const staticPath_assets_illust_concierge_bowing_2_svg = "/assets/illust/concierge-bowing-2.svg";
export const staticPath_assets_illust_concierge_bowing_svg = "/assets/illust/concierge-bowing.svg";
export const staticPath_assets_illust_concierge_fight_svg = "/assets/illust/concierge-fight.svg";
export const staticPath_assets_illust_concierge_free_svg = "/assets/illust/concierge-free.svg";
export const staticPath_assets_illust_concierge_smile_svg = "/assets/illust/concierge-smile.svg";
export const staticPath_assets_illust_concierge_sweat_svg = "/assets/illust/concierge-sweat.svg";
export const staticPath_assets_illust_concierge_three_contents_2_svg = "/assets/illust/concierge-three-contents-2.svg";
export const staticPath_assets_illust_concierge_three_contents_svg = "/assets/illust/concierge-three-contents.svg";
export const staticPath_assets_illust_concierge_svg = "/assets/illust/concierge.svg";
export const staticPath_assets_illust_connecting_hand_svg = "/assets/illust/connecting-hand.svg";
export const staticPath_assets_illust_connecting_svg = "/assets/illust/connecting.svg";
export const staticPath_assets_illust_considering_assets_woman_png = "/assets/illust/considering-assets-woman.png";
export const staticPath_assets_illust_consultation_png = "/assets/illust/consultation.png";
export const staticPath_assets_illust_consulting_suggestion_svg = "/assets/illust/consulting-suggestion.svg";
export const staticPath_assets_illust_education_expenses_png = "/assets/illust/education-expenses.png";
export const staticPath_assets_illust_female_steady_png = "/assets/illust/female-steady.png";
export const staticPath_assets_illust_female_to_see_smartphone_happily_png = "/assets/illust/female-to-see-smartphone-happily.png";
export const staticPath_assets_illust_free_advice_online_text_png = "/assets/illust/free-advice-online-text.png";
export const staticPath_assets_illust_gg_check_o_png = "/assets/illust/gg_check-o.png";
export const staticPath_assets_illust_goal_after_png = "/assets/illust/goal-after.png";
export const staticPath_assets_illust_goal_before_png = "/assets/illust/goal-before.png";
export const staticPath_assets_illust_hand_with_smartphone_svg = "/assets/illust/hand-with-smartphone.svg";
export const staticPath_assets_illust_hokuhoku_png = "/assets/illust/hokuhoku.png";
export const staticPath_assets_illust_ideco_desc_woman_png = "/assets/illust/ideco-desc-woman.png";
export const staticPath_assets_illust_ideco_setsuzei_png = "/assets/illust/ideco-setsuzei.png";
export const staticPath_assets_illust_instructor_1_svg = "/assets/illust/instructor-1.svg";
export const staticPath_assets_illust_instructor_2_svg = "/assets/illust/instructor-2.svg";
export const staticPath_assets_illust_line_left_png = "/assets/illust/line_left.png";
export const staticPath_assets_illust_line_right_png = "/assets/illust/line_right.png";
export const staticPath_assets_illust_mail_with_lamp_svg = "/assets/illust/mail-with-lamp.svg";
export const staticPath_assets_illust_male_steady_svg = "/assets/illust/male-steady.svg";
export const staticPath_assets_illust_male_studying_svg = "/assets/illust/male-studying.svg";
export const staticPath_assets_illust_money_png = "/assets/illust/money.png";
export const staticPath_assets_illust_money_svg = "/assets/illust/money.svg";
export const staticPath_assets_illust_nisa_desc_woman_png = "/assets/illust/nisa-desc-woman.png";
export const staticPath_assets_illust_nisa_growth_man_png = "/assets/illust/nisa-growth-man.png";
export const staticPath_assets_illust_nisa_ideco_png = "/assets/illust/nisa-ideco.png";
export const staticPath_assets_illust_nisa_revise_desc_woman_png = "/assets/illust/nisa-revise-desc-woman.png";
export const staticPath_assets_illust_noservice_svg = "/assets/illust/noservice.svg";
export const staticPath_assets_illust_older_couple_svg = "/assets/illust/older-couple.svg";
export const staticPath_assets_illust_patient_svg = "/assets/illust/patient.svg";
export const staticPath_assets_illust_pc_chart_png = "/assets/illust/pc-chart.png";
export const staticPath_assets_illust_raise_zero_yen_svg = "/assets/illust/raise-zero-yen.svg";
export const staticPath_assets_illust_smartphone_wipe_woman_svg = "/assets/illust/smartphone-wipe-woman.svg";
export const staticPath_assets_illust_star_concierge_png = "/assets/illust/star-concierge.png";
export const staticPath_assets_illust_top_main_visual_svg = "/assets/illust/top-main-visual.svg";
export const staticPath_assets_illust_toshin_desc_3_woman_png = "/assets/illust/toshin-desc-3-woman.png";
export const staticPath_assets_illust_toshin_desc_3_png = "/assets/illust/toshin-desc-3.png";
export const staticPath_assets_illust_tsumi_nisa_desc_woman_png = "/assets/illust/tsumi-nisa-desc-woman.png";
export const staticPath_assets_illust_tsumitate_nisa_base_png = "/assets/illust/tsumitate-nisa-base.png";
export const staticPath_assets_illust_tsumitate_nisa_md_png = "/assets/illust/tsumitate-nisa-md.png";
export const staticPath_assets_illust_tsumitate_nisa_svg = "/assets/illust/tsumitate-nisa.svg";
export const staticPath_assets_illust_type_prudent_male_svg = "/assets/illust/type-prudent-male.svg";
export const staticPath_assets_illust_user_female_1_circle_svg = "/assets/illust/user-female-1-circle.svg";
export const staticPath_assets_illust_user_female_1_svg = "/assets/illust/user-female-1.svg";
export const staticPath_assets_illust_user_female_2_circle_svg = "/assets/illust/user-female-2-circle.svg";
export const staticPath_assets_illust_user_female_2_svg = "/assets/illust/user-female-2.svg";
export const staticPath_assets_illust_user_female_3_circle_svg = "/assets/illust/user-female-3-circle.svg";
export const staticPath_assets_illust_user_female_4_circle_svg = "/assets/illust/user-female-4-circle.svg";
export const staticPath_assets_illust_user_female_5_svg = "/assets/illust/user-female-5.svg";
export const staticPath_assets_illust_user_female_6_svg = "/assets/illust/user-female-6.svg";
export const staticPath_assets_illust_user_female_7_svg = "/assets/illust/user-female-7.svg";
export const staticPath_assets_illust_user_getlost_png = "/assets/illust/user-getlost.png";
export const staticPath_assets_illust_user_male_1_circle_svg = "/assets/illust/user-male-1-circle.svg";
export const staticPath_assets_illust_user_male_1_svg = "/assets/illust/user-male-1.svg";
export const staticPath_assets_illust_user_male_2_svg = "/assets/illust/user-male-2.svg";
export const staticPath_assets_illust_user_understand_svg = "/assets/illust/user-understand.svg";
export const staticPath_assets_illust_user_female_sigh_svg = "/assets/illust/user_female_sigh.svg";
export const staticPath_assets_illust_woman_3_of_each_age_png = "/assets/illust/woman-3-of-each-age.png";
export const staticPath_assets_illust_woman_3_png = "/assets/illust/woman-3.png";
export const staticPath_assets_illust_woman_inspired_png = "/assets/illust/woman-inspired.png";
export const staticPath_assets_illust_woman_introduction_for_couple_png = "/assets/illust/woman-introduction-for-couple.png";
export const staticPath_assets_illust_woman_toushi_image_svg = "/assets/illust/woman-toushi-image.svg";
export const staticPath_assets_illust_worried_man_upper_body_svg = "/assets/illust/worried-man-upper-body.svg";
export const staticPath_assets_illusts_couple_couple50s_svg = "/assets/illusts/couple/couple50s.svg";
export const staticPath_assets_illusts_couple_fufugenka_svg = "/assets/illusts/couple/fufugenka.svg";
export const staticPath_assets_illusts_couple_fufugenka2_svg = "/assets/illusts/couple/fufugenka2.svg";
export const staticPath_assets_illusts_couple_fufuhanashiai_svg = "/assets/illusts/couple/fufuhanashiai.svg";
export const staticPath_assets_illusts_couple_fufuhirameku_svg = "/assets/illusts/couple/fufuhirameku.svg";
export const staticPath_assets_illusts_couple_fufukangaeru_svg = "/assets/illusts/couple/fufukangaeru.svg";
export const staticPath_assets_illusts_couple_fufukomatta_svg = "/assets/illusts/couple/fufukomatta.svg";
export const staticPath_assets_illusts_couple_miboujin_svg = "/assets/illusts/couple/miboujin.svg";
export const staticPath_assets_illusts_couple_otto_kikoenai_svg = "/assets/illusts/couple/otto-kikoenai.svg";
export const staticPath_assets_illusts_couple_otto_kyohi_ukeuri_svg = "/assets/illusts/couple/otto-kyohi-ukeuri.svg";
export const staticPath_assets_illusts_couple_otto_kyohi_svg = "/assets/illusts/couple/otto-kyohi.svg";
export const staticPath_assets_illusts_couple_otto_mukanshin_svg = "/assets/illusts/couple/otto-mukanshin.svg";
export const staticPath_assets_illusts_couple_otto_shitsugyou_svg = "/assets/illusts/couple/otto-shitsugyou.svg";
export const staticPath_assets_illusts_couple_otto_shittakaburi_svg = "/assets/illusts/couple/otto-shittakaburi.svg";
export const staticPath_assets_illusts_couple_roufufu_yutorinashi_svg = "/assets/illusts/couple/roufufu-yutorinashi.svg";
export const staticPath_assets_illusts_couple_roufufu_svg = "/assets/illusts/couple/roufufu.svg";
export const staticPath_assets_illusts_couple_tsuma_iratsuki_svg = "/assets/illusts/couple/tsuma-iratsuki.svg";
export const staticPath_assets_illusts_family_family_2people_svg = "/assets/illusts/family/family-2people.svg";
export const staticPath_assets_illusts_family_family_3people_svg = "/assets/illusts/family/family-3people.svg";
export const staticPath_assets_illusts_family_family_4people_svg = "/assets/illusts/family/family-4people.svg";
export const staticPath_assets_illusts_family_family_dauhter_benkyo_svg = "/assets/illusts/family/family-dauhter-benkyo.svg";
export const staticPath_assets_illusts_family_family_dauhter_svg = "/assets/illusts/family/family-dauhter.svg";
export const staticPath_assets_illusts_family_family_ninkatu_svg = "/assets/illusts/family/family-ninkatu.svg";
export const staticPath_assets_illusts_family_family_ryokou_svg = "/assets/illusts/family/family-ryokou.svg";
export const staticPath_assets_illusts_family_family_shufu_svg = "/assets/illusts/family/family-shufu.svg";
export const staticPath_assets_illusts_family_family_son_benkyo_svg = "/assets/illusts/family/family-son-benkyo.svg";
export const staticPath_assets_illusts_family_family_son_hitorigurashi_svg = "/assets/illusts/family/family-son-hitorigurashi.svg";
export const staticPath_assets_illusts_family_family_son_shiken_svg = "/assets/illusts/family/family-son-shiken.svg";
export const staticPath_assets_illusts_family_family_son_soccer_svg = "/assets/illusts/family/family-son-soccer.svg";
export const staticPath_assets_illusts_family_family_son_toukou_svg = "/assets/illusts/family/family-son-toukou.svg";
export const staticPath_assets_illusts_family_family_son_svg = "/assets/illusts/family/family-son.svg";
export const staticPath_assets_illusts_insta_ic_creditcard_svg = "/assets/illusts/insta/ic-creditcard.svg";
export const staticPath_assets_illusts_insta_ic_gamaguchi_svg = "/assets/illusts/insta/ic-gamaguchi.svg";
export const staticPath_assets_illusts_insta_ic_house_svg = "/assets/illusts/insta/ic-house.svg";
export const staticPath_assets_illusts_insta_ic_okanefukuro_svg = "/assets/illusts/insta/ic-okanefukuro.svg";
export const staticPath_assets_illusts_insta_ic_ranking_1_svg = "/assets/illusts/insta/ic-ranking-1.svg";
export const staticPath_assets_illusts_insta_ic_ranking_2_svg = "/assets/illusts/insta/ic-ranking-2.svg";
export const staticPath_assets_illusts_insta_ic_ranking_3_svg = "/assets/illusts/insta/ic-ranking-3.svg";
export const staticPath_assets_illusts_insta_ic_shitsumon_svg = "/assets/illusts/insta/ic-shitsumon.svg";
export const staticPath_assets_illusts_insta_ic_sodan_svg = "/assets/illusts/insta/ic-sodan.svg";
export const staticPath_assets_illusts_insta_ic_tsumitate_svg = "/assets/illusts/insta/ic-tsumitate.svg";
export const staticPath_assets_illusts_man_concierge_mc_main_svg = "/assets/illusts/man-concierge/mc-main.svg";
export const staticPath_assets_illusts_man_concierge_mc_sub_svg = "/assets/illusts/man-concierge/mc-sub.svg";
export const staticPath_assets_illusts_mono_atm_svg = "/assets/illusts/mono/atm.svg";
export const staticPath_assets_illusts_mono_camera_off_svg = "/assets/illusts/mono/camera-off.svg";
export const staticPath_assets_illusts_mono_coin_svg = "/assets/illusts/mono/coin.svg";
export const staticPath_assets_illusts_mono_gakushi_svg = "/assets/illusts/mono/gakushi.svg";
export const staticPath_assets_illusts_mono_graph_svg = "/assets/illusts/mono/graph.svg";
export const staticPath_assets_illusts_mono_icon_ideco_svg = "/assets/illusts/mono/icon-ideco.svg";
export const staticPath_assets_illusts_mono_icon_kyoiku_svg = "/assets/illusts/mono/icon-kyoiku.svg";
export const staticPath_assets_illusts_mono_icon_nisa_svg = "/assets/illusts/mono/icon-nisa.svg";
export const staticPath_assets_illusts_mono_icon_shoshinsha_svg = "/assets/illusts/mono/icon-shoshinsha.svg";
export const staticPath_assets_illusts_mono_kaiyaku_svg = "/assets/illusts/mono/kaiyaku.svg";
export const staticPath_assets_illusts_mono_kaiyakusho_svg = "/assets/illusts/mono/kaiyakusho.svg";
export const staticPath_assets_illusts_mono_letterset_svg = "/assets/illusts/mono/letterset.svg";
export const staticPath_assets_illusts_mono_mail_svg = "/assets/illusts/mono/mail.svg";
export const staticPath_assets_illusts_mono_mirufiyu_sara_svg = "/assets/illusts/mono/mirufiyu-sara.svg";
export const staticPath_assets_illusts_mono_mirufiyu_svg = "/assets/illusts/mono/mirufiyu.svg";
export const staticPath_assets_illusts_mono_money_svg = "/assets/illusts/mono/money.svg";
export const staticPath_assets_illusts_mono_mono_ideconisa_svg = "/assets/illusts/mono/mono-ideconisa.svg";
export const staticPath_assets_illusts_mono_omamori_svg = "/assets/illusts/mono/omamori.svg";
export const staticPath_assets_illusts_mono_paper_notification_svg = "/assets/illusts/mono/paper-notification.svg";
export const staticPath_assets_illusts_mono_pc_chart_line_svg = "/assets/illusts/mono/pc-chart-line.svg";
export const staticPath_assets_illusts_mono_pc_chart_pie_svg = "/assets/illusts/mono/pc-chart-pie.svg";
export const staticPath_assets_illusts_mono_pc_svg = "/assets/illusts/mono/pc.svg";
export const staticPath_assets_illusts_mono_pig_chokinbako_line_bold_svg = "/assets/illusts/mono/pig-chokinbako-line-bold.svg";
export const staticPath_assets_illusts_mono_pig_chokinbako_svg = "/assets/illusts/mono/pig-chokinbako.svg";
export const staticPath_assets_illusts_mono_satsutaba_svg = "/assets/illusts/mono/satsutaba.svg";
export const staticPath_assets_illusts_mono_sp_pc_svg = "/assets/illusts/mono/sp-pc.svg";
export const staticPath_assets_illusts_mono_sp_svg = "/assets/illusts/mono/sp.svg";
export const staticPath_assets_illusts_mono_tenbin_svg = "/assets/illusts/mono/tenbin.svg";
export const staticPath_assets_illusts_mono_tsuchou_svg = "/assets/illusts/mono/tsuchou.svg";
export const staticPath_assets_illusts_mono_zoom_swipe_svg = "/assets/illusts/mono/zoom_swipe.svg";
export const staticPath_assets_illusts_real_concierge_asuka_svg = "/assets/illusts/real-concierge/asuka.svg";
export const staticPath_assets_illusts_real_concierge_izumida_svg = "/assets/illusts/real-concierge/izumida.svg";
export const staticPath_assets_illusts_real_concierge_kamura_svg = "/assets/illusts/real-concierge/kamura.svg";
export const staticPath_assets_illusts_real_concierge_miwa_svg = "/assets/illusts/real-concierge/miwa.svg";
export const staticPath_assets_illusts_real_concierge_miyazawa_svg = "/assets/illusts/real-concierge/miyazawa.svg";
export const staticPath_assets_illusts_real_concierge_morishige_svg = "/assets/illusts/real-concierge/morishige.svg";
export const staticPath_assets_illusts_real_concierge_ozaki_svg = "/assets/illusts/real-concierge/ozaki.svg";
export const staticPath_assets_illusts_real_concierge_sato_svg = "/assets/illusts/real-concierge/sato.svg";
export const staticPath_assets_illusts_shindan_man_ando_svg = "/assets/illusts/shindan/man-ando.svg";
export const staticPath_assets_illusts_shindan_man_diy_kenjitsu_svg = "/assets/illusts/shindan/man-diy-kenjitsu.svg";
export const staticPath_assets_illusts_shindan_man_diy_shoubushi_svg = "/assets/illusts/shindan/man-diy-shoubushi.svg";
export const staticPath_assets_illusts_shindan_man_kenjitsu_svg = "/assets/illusts/shindan/man-kenjitsu.svg";
export const staticPath_assets_illusts_shindan_man_kyougaku_svg = "/assets/illusts/shindan/man-kyougaku.svg";
export const staticPath_assets_illusts_shindan_man_shindan_start_svg = "/assets/illusts/shindan/man-shindan-start.svg";
export const staticPath_assets_illusts_shindan_man_shoubushi_svg = "/assets/illusts/shindan/man-shoubushi.svg";
export const staticPath_assets_illusts_shindan_woman_ando_svg = "/assets/illusts/shindan/woman-ando.svg";
export const staticPath_assets_illusts_shindan_woman_diy_kenjitsu_svg = "/assets/illusts/shindan/woman-diy-kenjitsu.svg";
export const staticPath_assets_illusts_shindan_woman_diy_shoubushi_svg = "/assets/illusts/shindan/woman-diy-shoubushi.svg";
export const staticPath_assets_illusts_shindan_woman_goal_mienai_svg = "/assets/illusts/shindan/woman-goal-mienai.svg";
export const staticPath_assets_illusts_shindan_woman_goal_mieta_svg = "/assets/illusts/shindan/woman-goal-mieta.svg";
export const staticPath_assets_illusts_shindan_woman_hirameki_svg = "/assets/illusts/shindan/woman-hirameki.svg";
export const staticPath_assets_illusts_shindan_woman_kenjitsu_svg = "/assets/illusts/shindan/woman-kenjitsu.svg";
export const staticPath_assets_illusts_shindan_woman_kyougaku_svg = "/assets/illusts/shindan/woman-kyougaku.svg";
export const staticPath_assets_illusts_shindan_woman_shindan_start_svg = "/assets/illusts/shindan/woman-shindan-start.svg";
export const staticPath_assets_illusts_shindan_woman_shoubushi_svg = "/assets/illusts/shindan/woman-shoubushi.svg";
export const staticPath_assets_illusts_shiryo_byositsu_svg = "/assets/illusts/shiryo/byositsu.svg";
export const staticPath_assets_illusts_shiryo_furusatonozei_svg = "/assets/illusts/shiryo/furusatonozei.svg";
export const staticPath_assets_illusts_shiryo_man_byouki_svg = "/assets/illusts/shiryo/man-byouki.svg";
export const staticPath_assets_illusts_shiryo_man_ctr_svg = "/assets/illusts/shiryo/man-ctr.svg";
export const staticPath_assets_illusts_shiryo_man_fuyuso_svg = "/assets/illusts/shiryo/man-fuyuso.svg";
export const staticPath_assets_illusts_shiryo_man_jyusin_svg = "/assets/illusts/shiryo/man-jyusin.svg";
export const staticPath_assets_illusts_shiryo_man_rihabiri_svg = "/assets/illusts/shiryo/man-rihabiri.svg";
export const staticPath_assets_illusts_shiryo_man_rosai_svg = "/assets/illusts/shiryo/man-rosai.svg";
export const staticPath_assets_illusts_shiryo_man_taiin_svg = "/assets/illusts/shiryo/man-taiin.svg";
export const staticPath_assets_illusts_shiryo_man_yakusho_svg = "/assets/illusts/shiryo/man-yakusho.svg";
export const staticPath_assets_illusts_shiryo_nouka_svg = "/assets/illusts/shiryo/nouka.svg";
export const staticPath_assets_illusts_shiryo_noukousoku_svg = "/assets/illusts/shiryo/noukousoku.svg";
export const staticPath_assets_illusts_shiryo_nurse_svg = "/assets/illusts/shiryo/nurse.svg";
export const staticPath_assets_illusts_shiryo_police_svg = "/assets/illusts/shiryo/police.svg";
export const staticPath_assets_illusts_shiryo_teinen_hataraku_svg = "/assets/illusts/shiryo/teinen-hataraku.svg";
export const staticPath_assets_illusts_shiryo_woman_60_over_svg = "/assets/illusts/shiryo/woman-60-over.svg";
export const staticPath_assets_illusts_shiryo_woman_byouki_svg = "/assets/illusts/shiryo/woman-byouki.svg";
export const staticPath_assets_illusts_shiryo_woman_kega_svg = "/assets/illusts/shiryo/woman-kega.svg";
export const staticPath_assets_illusts_shiryo_woman_kenkou_svg = "/assets/illusts/shiryo/woman-kenkou.svg";
export const staticPath_assets_illusts_shiryo_woman_metabo_svg = "/assets/illusts/shiryo/woman-metabo.svg";
export const staticPath_assets_illusts_shiryo_woman_new_tsumitate_nisa_svg = "/assets/illusts/shiryo/woman-new-tsumitate-nisa.svg";
export const staticPath_assets_illusts_shiryo_woman_nyuin_svg = "/assets/illusts/shiryo/woman-nyuin.svg";
export const staticPath_assets_illusts_shiryo_woman_nyuinhi_svg = "/assets/illusts/shiryo/woman-nyuinhi.svg";
export const staticPath_assets_illusts_shiryo_woman_sagasu_nisa_svg = "/assets/illusts/shiryo/woman-sagasu-nisa.svg";
export const staticPath_assets_illusts_shiryo_woman_toushi_image_svg = "/assets/illusts/shiryo/woman-toushi-image.svg";
export const staticPath_assets_illusts_shiryo_woman_tsumitate_nisa_svg = "/assets/illusts/shiryo/woman-tsumitate-nisa.svg";
export const staticPath_assets_illusts_shiryo_woman_yukidama_korogasu_svg = "/assets/illusts/shiryo/woman-yukidama-korogasu.svg";
export const staticPath_assets_illusts_shiryo_woman_yukidama_tsukuru_svg = "/assets/illusts/shiryo/woman-yukidama-tsukuru.svg";
export const staticPath_assets_illusts_smartphone_sp_fufu_kaisetsu_wc_svg = "/assets/illusts/smartphone/sp-fufu-kaisetsu-wc.svg";
export const staticPath_assets_illusts_smartphone_sp_man_sodan_svg = "/assets/illusts/smartphone/sp-man-sodan.svg";
export const staticPath_assets_illusts_smartphone_sp_woma_kaisetsu_wc_svg = "/assets/illusts/smartphone/sp-woma-kaisetsu-wc.svg";
export const staticPath_assets_illusts_smartphone_sp_woman_check_svg = "/assets/illusts/smartphone/sp-woman-check.svg";
export const staticPath_assets_illusts_smartphone_sp_woman_hikaku_svg = "/assets/illusts/smartphone/sp-woman-hikaku.svg";
export const staticPath_assets_illusts_smartphone_sp_woman_kaisetsu_mc_svg = "/assets/illusts/smartphone/sp-woman-kaisetsu-mc.svg";
export const staticPath_assets_illusts_smartphone_sp_woman_sodan_svg = "/assets/illusts/smartphone/sp-woman-sodan.svg";
export const staticPath_assets_illusts_smartphone_sp_woman_yoyaku_svg = "/assets/illusts/smartphone/sp-woman-yoyaku.svg";
export const staticPath_assets_illusts_user_man_30_main_svg = "/assets/illusts/user/man-30-main.svg";
export const staticPath_assets_illusts_user_man_30_sub_svg = "/assets/illusts/user/man-30-sub.svg";
export const staticPath_assets_illusts_user_man_40_main_svg = "/assets/illusts/user/man-40-main.svg";
export const staticPath_assets_illusts_user_man_50_main_svg = "/assets/illusts/user/man-50-main.svg";
export const staticPath_assets_illusts_user_man_50_sub_svg = "/assets/illusts/user/man-50-sub.svg";
export const staticPath_assets_illusts_user_man_60_main_svg = "/assets/illusts/user/man-60-main.svg";
export const staticPath_assets_illusts_user_woman_30_main_svg = "/assets/illusts/user/woman-30-main.svg";
export const staticPath_assets_illusts_user_woman_30_sub_svg = "/assets/illusts/user/woman-30-sub.svg";
export const staticPath_assets_illusts_user_woman_40_main_svg = "/assets/illusts/user/woman-40-main.svg";
export const staticPath_assets_illusts_user_woman_40_sub_svg = "/assets/illusts/user/woman-40-sub.svg";
export const staticPath_assets_illusts_user_woman_50_main_svg = "/assets/illusts/user/woman-50-main.svg";
export const staticPath_assets_illusts_user_woman_60_over_svg = "/assets/illusts/user/woman-60-over.svg";
export const staticPath_assets_illusts_wakaranai_man_moyamoya_svg = "/assets/illusts/wakaranai/man-moyamoya.svg";
export const staticPath_assets_illusts_wakaranai_man_komaru_svg = "/assets/illusts/wakaranai/man_komaru.svg";
export const staticPath_assets_illusts_wakaranai_woman_kaigohiyou_futan_svg = "/assets/illusts/wakaranai/woman-kaigohiyou-futan.svg";
export const staticPath_assets_illusts_wakaranai_woman_kanshin_svg = "/assets/illusts/wakaranai/woman-kanshin.svg";
export const staticPath_assets_illusts_wakaranai_woman_kimerarenai_nisaideco_svg = "/assets/illusts/wakaranai/woman-kimerarenai-nisaideco.svg";
export const staticPath_assets_illusts_wakaranai_woman_kimerarenai_svg = "/assets/illusts/wakaranai/woman-kimerarenai.svg";
export const staticPath_assets_illusts_wakaranai_woman_komaru_svg = "/assets/illusts/wakaranai/woman-komaru.svg";
export const staticPath_assets_illusts_wakaranai_woman_moyamoya_svg = "/assets/illusts/wakaranai/woman-moyamoya.svg";
export const staticPath_assets_illusts_wakaranai_woman_setsuzei_ideco_svg = "/assets/illusts/wakaranai/woman-setsuzei-ideco.svg";
export const staticPath_assets_illusts_wakaranai_woman_setsuzei_svg = "/assets/illusts/wakaranai/woman-setsuzei.svg";
export const staticPath_assets_illusts_wakaranai_woman_shinchou_svg = "/assets/illusts/wakaranai/woman-shinchou.svg";
export const staticPath_assets_illusts_wakaranai_woman_wakaranai_svg = "/assets/illusts/wakaranai/woman-wakaranai.svg";
export const staticPath_assets_illusts_wakaranai_woman_zasetsu_svg = "/assets/illusts/wakaranai/woman-zasetsu.svg";
export const staticPath_assets_illusts_woman_concierge_wc_ayamaru_svg = "/assets/illusts/woman-concierge/wc-ayamaru.svg";
export const staticPath_assets_illusts_woman_concierge_wc_basic_svg = "/assets/illusts/woman-concierge/wc-basic.svg";
export const staticPath_assets_illusts_woman_concierge_wc_chart_svg = "/assets/illusts/woman-concierge/wc-chart.svg";
export const staticPath_assets_illusts_woman_concierge_wc_hikaku_svg = "/assets/illusts/woman-concierge/wc-hikaku.svg";
export const staticPath_assets_illusts_woman_concierge_wc_instructor_svg = "/assets/illusts/woman-concierge/wc-instructor.svg";
export const staticPath_assets_illusts_woman_concierge_wc_kinshi_svg = "/assets/illusts/woman-concierge/wc-kinshi.svg";
export const staticPath_assets_illusts_woman_concierge_wc_ojigi_svg = "/assets/illusts/woman-concierge/wc-ojigi.svg";
export const staticPath_assets_illusts_woman_concierge_wc_ouen_svg = "/assets/illusts/woman-concierge/wc-ouen.svg";
export const staticPath_assets_illusts_woman_concierge_wc_sagasu_svg = "/assets/illusts/woman-concierge/wc-sagasu.svg";
export const staticPath_assets_illusts_woman_concierge_wc_smile_svg = "/assets/illusts/woman-concierge/wc-smile.svg";
export const staticPath_assets_illusts_woman_concierge_wc_sodan_svg = "/assets/illusts/woman-concierge/wc-sodan.svg";
export const staticPath_assets_illusts_woman_concierge_wc_star_svg = "/assets/illusts/woman-concierge/wc-star.svg";
export const staticPath_assets_illusts_woman_concierge_wc_sub_svg = "/assets/illusts/woman-concierge/wc-sub.svg";
export const staticPath_assets_illusts_woman_concierge_wc_unazuku_svg = "/assets/illusts/woman-concierge/wc-unazuku.svg";
export const staticPath_assets_intention_checkbox_svg = "/assets/intention/checkbox.svg";
export const staticPath_assets_intention_empty_checkbox_svg = "/assets/intention/empty-checkbox.svg";
export const staticPath_assets_intention_flow_svg = "/assets/intention/flow.svg";
export const staticPath_assets_intention_heart_check_svg = "/assets/intention/heart-check.svg";
export const staticPath_assets_intention_ideco_tsumitate_graphq_png = "/assets/intention/ideco-tsumitate-graphq.png";
export const staticPath_assets_intention_investment_trust_description_svg = "/assets/intention/investment-trust-description.svg";
export const staticPath_assets_intention_loss_taxable_graph_svg = "/assets/intention/loss-taxable-graph.svg";
export const staticPath_assets_intention_marunouchi_png = "/assets/intention/marunouchi.png";
export const staticPath_assets_intention_matrix_png = "/assets/intention/matrix.png";
export const staticPath_assets_intention_satisfaction_svg = "/assets/intention/satisfaction.svg";
export const staticPath_assets_intention_stable_graph_svg = "/assets/intention/stable-graph.svg";
export const staticPath_assets_intention_tax_graph_png = "/assets/intention/tax-graph.png";
export const staticPath_assets_intention_tsumitate_graph_svg = "/assets/intention/tsumitate-graph.svg";
export const staticPath_assets_intention_tsumitate_maintenance_graph_svg = "/assets/intention/tsumitate-maintenance-graph.svg";
export const staticPath_assets_intention_umeda_png = "/assets/intention/umeda.png";
export const staticPath_assets_intention_user_graph_svg = "/assets/intention/user-graph.svg";
export const staticPath_assets_intention_woman_low_risk_svg = "/assets/intention/woman-low-risk.svg";
export const staticPath_assets_invitations_arrow_right_png = "/assets/invitations/arrow-right.png";
export const staticPath_assets_invitations_hero_base_png = "/assets/invitations/hero-base.png";
export const staticPath_assets_invitations_hero_md_png = "/assets/invitations/hero-md.png";
export const staticPath_assets_invitations_mail_svg = "/assets/invitations/mail.svg";
export const staticPath_assets_layout_header_base_banner_png = "/assets/layout/header/base/banner.png";
export const staticPath_assets_layout_header_base_consulting_icon_png = "/assets/layout/header/base/consulting-icon.png";
export const staticPath_assets_layout_header_base_instagram_icon_png = "/assets/layout/header/base/instagram-icon.png";
export const staticPath_assets_layout_header_base_line_icon_png = "/assets/layout/header/base/line-icon.png";
export const staticPath_assets_layout_header_base_mypage_icon_png = "/assets/layout/header/base/mypage-icon.png";
export const staticPath_assets_layout_header_base_seminar_icon_png = "/assets/layout/header/base/seminar-icon.png";
export const staticPath_assets_layout_header_base_simulation_icon_png = "/assets/layout/header/base/simulation-icon.png";
export const staticPath_assets_layout_header_md_3_funds_seminar_png = "/assets/layout/header/md/3-funds-seminar.png";
export const staticPath_assets_layout_header_md_edu_consulting_png = "/assets/layout/header/md/edu-consulting.png";
export const staticPath_assets_layout_header_md_future_consulting_png = "/assets/layout/header/md/future-consulting.png";
export const staticPath_assets_layout_header_md_hand_with_sp_png = "/assets/layout/header/md/hand-with-sp.png";
export const staticPath_assets_layout_header_md_ideco_consulting_png = "/assets/layout/header/md/ideco-consulting.png";
export const staticPath_assets_layout_header_md_ideco_seminar_png = "/assets/layout/header/md/ideco-seminar.png";
export const staticPath_assets_layout_header_md_ideco2_seminar_png = "/assets/layout/header/md/ideco2-seminar.png";
export const staticPath_assets_layout_header_md_insurance_consulting_png = "/assets/layout/header/md/insurance-consulting.png";
export const staticPath_assets_layout_header_md_mortgage_seminar_png = "/assets/layout/header/md/mortgage-seminar.png";
export const staticPath_assets_layout_header_md_mutual_funds_consulting_png = "/assets/layout/header/md/mutual-funds-consulting.png";
export const staticPath_assets_layout_header_md_nisa_100_seminar_png = "/assets/layout/header/md/nisa-100-seminar.png";
export const staticPath_assets_layout_header_md_nisa_basic_seminar_png = "/assets/layout/header/md/nisa-basic-seminar.png";
export const staticPath_assets_layout_header_md_nisa_basic2_seminar_png = "/assets/layout/header/md/nisa-basic2-seminar.png";
export const staticPath_assets_layout_header_md_nisa_basic3_seminar_png = "/assets/layout/header/md/nisa-basic3-seminar.png";
export const staticPath_assets_layout_header_md_nisa_consulting_png = "/assets/layout/header/md/nisa-consulting.png";
export const staticPath_assets_layout_header_md_nisa_growth_seminar_png = "/assets/layout/header/md/nisa-growth-seminar.png";
export const staticPath_assets_layout_header_md_nisa_levelup_seminar_png = "/assets/layout/header/md/nisa-levelup-seminar.png";
export const staticPath_assets_layout_header_md_nisa_revise_seminar_png = "/assets/layout/header/md/nisa-revise-seminar.png";
export const staticPath_assets_layout_header_md_smart_seminar_png = "/assets/layout/header/md/smart-seminar.png";
export const staticPath_assets_layout_header_md_solution_50s_seminar_png = "/assets/layout/header/md/solution-50s-seminar.png";
export const staticPath_assets_lp_3_funds_hero_base_png = "/assets/lp/3-funds/hero-base.png";
export const staticPath_assets_lp_3_funds_hero_md_png = "/assets/lp/3-funds/hero-md.png";
export const staticPath_assets_lp_3_funds_ogp_png = "/assets/lp/3-funds/ogp.png";
export const staticPath_assets_lp_3_funds_recommend_base_png = "/assets/lp/3-funds/recommend-base.png";
export const staticPath_assets_lp_3_funds_recommend_md_png = "/assets/lp/3-funds/recommend-md.png";
export const staticPath_assets_lp_3_funds_slide01_01_png = "/assets/lp/3-funds/slide01_01.png";
export const staticPath_assets_lp_3_funds_slide01_02_png = "/assets/lp/3-funds/slide01_02.png";
export const staticPath_assets_lp_3_funds_slide01_03_png = "/assets/lp/3-funds/slide01_03.png";
export const staticPath_assets_lp_3_funds_slide02_01_png = "/assets/lp/3-funds/slide02_01.png";
export const staticPath_assets_lp_3_funds_slide02_02_png = "/assets/lp/3-funds/slide02_02.png";
export const staticPath_assets_lp_3_funds_slide02_03_png = "/assets/lp/3-funds/slide02_03.png";
export const staticPath_assets_lp_common_applications_text_png = "/assets/lp/common/applications-text.png";
export const staticPath_assets_lp_common_checkbox_png = "/assets/lp/common/checkbox.png";
export const staticPath_assets_lp_common_consulting_participant_age_graph_png = "/assets/lp/common/consulting-participant-age-graph.png";
export const staticPath_assets_lp_common_graph_participants_age_png = "/assets/lp/common/graph-participants-age.png";
export const staticPath_assets_lp_common_graph_participants_financial_png = "/assets/lp/common/graph-participants-financial.png";
export const staticPath_assets_lp_common_manzokudo_badge_png = "/assets/lp/common/manzokudo-badge.png";
export const staticPath_assets_lp_common_merit_01_svg = "/assets/lp/common/merit-01.svg";
export const staticPath_assets_lp_common_merit_02_svg = "/assets/lp/common/merit-02.svg";
export const staticPath_assets_lp_common_number_of_visitors_badge_png = "/assets/lp/common/number-of-visitors_badge.png";
export const staticPath_assets_lp_common_preview_jpg = "/assets/lp/common/preview.jpg";
export const staticPath_assets_lp_common_star_png = "/assets/lp/common/star.png";
export const staticPath_assets_lp_common_taikensya_badge_png = "/assets/lp/common/taikensya-badge.png";
export const staticPath_assets_lp_consulting_edu_hero_base_png = "/assets/lp/consulting/edu/hero-base.png";
export const staticPath_assets_lp_consulting_edu_hero_md_png = "/assets/lp/consulting/edu/hero-md.png";
export const staticPath_assets_lp_consulting_edu_ogp_jpg = "/assets/lp/consulting/edu/ogp.jpg";
export const staticPath_assets_lp_consulting_future_hero_base_png = "/assets/lp/consulting/future/hero-base.png";
export const staticPath_assets_lp_consulting_future_hero_md_png = "/assets/lp/consulting/future/hero-md.png";
export const staticPath_assets_lp_consulting_future_ogp_jpg = "/assets/lp/consulting/future/ogp.jpg";
export const staticPath_assets_lp_consulting_ideco_hero_base_png = "/assets/lp/consulting/ideco/hero-base.png";
export const staticPath_assets_lp_consulting_ideco_hero_md_png = "/assets/lp/consulting/ideco/hero-md.png";
export const staticPath_assets_lp_consulting_ideco_ogp_jpg = "/assets/lp/consulting/ideco/ogp.jpg";
export const staticPath_assets_lp_consulting_insurance_hero_base_png = "/assets/lp/consulting/insurance/hero-base.png";
export const staticPath_assets_lp_consulting_insurance_hero_md_png = "/assets/lp/consulting/insurance/hero-md.png";
export const staticPath_assets_lp_consulting_insurance_ogp_jpg = "/assets/lp/consulting/insurance/ogp.jpg";
export const staticPath_assets_lp_consulting_insurance_woman_kanshin_with_card_jpg = "/assets/lp/consulting/insurance/woman-kanshin-with-card.jpg";
export const staticPath_assets_lp_consulting_mutual_funds_hero_base_png = "/assets/lp/consulting/mutual-funds/hero-base.png";
export const staticPath_assets_lp_consulting_mutual_funds_hero_md_png = "/assets/lp/consulting/mutual-funds/hero-md.png";
export const staticPath_assets_lp_consulting_mutual_funds_ogp_jpg = "/assets/lp/consulting/mutual-funds/ogp.jpg";
export const staticPath_assets_lp_consulting_nisa_hero_base_png = "/assets/lp/consulting/nisa/hero-base.png";
export const staticPath_assets_lp_consulting_nisa_hero_md_png = "/assets/lp/consulting/nisa/hero-md.png";
export const staticPath_assets_lp_consulting_nisa_ogp_png = "/assets/lp/consulting/nisa/ogp.png";
export const staticPath_assets_lp_consulting_sbi_hero_base_png = "/assets/lp/consulting/sbi/hero-base.png";
export const staticPath_assets_lp_consulting_sbi_hero_md_png = "/assets/lp/consulting/sbi/hero-md.png";
export const staticPath_assets_lp_consulting_2_hero_base_png = "/assets/lp/consulting-2/hero-base.png";
export const staticPath_assets_lp_consulting_2_hero_md_png = "/assets/lp/consulting-2/hero-md.png";
export const staticPath_assets_lp_consulting_edu_hero_base_jpg = "/assets/lp/consulting-edu/hero-base.jpg";
export const staticPath_assets_lp_consulting_edu_hero_md_jpg = "/assets/lp/consulting-edu/hero-md.jpg";
export const staticPath_assets_lp_consulting_edu_question_woman_png = "/assets/lp/consulting-edu/question_woman.png";
export const staticPath_assets_lp_consulting_woman_9_9_38_img_9926fea5a7967daa27323fb4abc6cf9e592_png = "/assets/lp/consulting-woman/9_9_38_img_9926fea5a7967daa27323fb4abc6cf9e592.png";
export const staticPath_assets_lp_consulting_woman_allow_png = "/assets/lp/consulting-woman/allow.png";
export const staticPath_assets_lp_consulting_woman_checkbox_png = "/assets/lp/consulting-woman/checkbox.png";
export const staticPath_assets_lp_consulting_woman_hero_base_jpg = "/assets/lp/consulting-woman/hero-base.jpg";
export const staticPath_assets_lp_consulting_woman_hero_md_jpg = "/assets/lp/consulting-woman/hero-md.jpg";
export const staticPath_assets_lp_consulting_woman_how_others_start_svg = "/assets/lp/consulting-woman/how-others-start.svg";
export const staticPath_assets_lp_consulting_woman_prefer_female_disabled_png = "/assets/lp/consulting-woman/prefer-female-disabled.png";
export const staticPath_assets_lp_consulting_woman_prefer_female_png = "/assets/lp/consulting-woman/prefer-female.png";
export const staticPath_assets_lp_consulting_woman_prefer_male_disabled_png = "/assets/lp/consulting-woman/prefer-male-disabled.png";
export const staticPath_assets_lp_consulting_woman_prefer_male_png = "/assets/lp/consulting-woman/prefer-male.png";
export const staticPath_assets_lp_consulting_woman_prefer_none_disabled_png = "/assets/lp/consulting-woman/prefer-none-disabled.png";
export const staticPath_assets_lp_consulting_woman_prefer_none_png = "/assets/lp/consulting-woman/prefer-none.png";
export const staticPath_assets_lp_consulting_woman_prefer_female_disabled_pc_png = "/assets/lp/consulting-woman/prefer_female_disabled_pc.png";
export const staticPath_assets_lp_consulting_woman_prefer_female_pc_png = "/assets/lp/consulting-woman/prefer_female_pc.png";
export const staticPath_assets_lp_consulting_woman_prefer_male_disabled_pc_png = "/assets/lp/consulting-woman/prefer_male_disabled_pc.png";
export const staticPath_assets_lp_consulting_woman_prefer_male_pc_png = "/assets/lp/consulting-woman/prefer_male_pc.png";
export const staticPath_assets_lp_consulting_woman_prefer_none_disabled_pc_png = "/assets/lp/consulting-woman/prefer_none_disabled_pc.png";
export const staticPath_assets_lp_consulting_woman_prefer_none_pc_png = "/assets/lp/consulting-woman/prefer_none_pc.png";
export const staticPath_assets_lp_consulting_woman_speech_balloon_png = "/assets/lp/consulting-woman/speech-balloon.png";
export const staticPath_assets_lp_consulting_woman_steps_content_pc_kojinsa_png = "/assets/lp/consulting-woman/steps-content-pc-kojinsa.png";
export const staticPath_assets_lp_consulting_woman_steps_content_pc_moneiro_step_png = "/assets/lp/consulting-woman/steps-content-pc-moneiro-step.png";
export const staticPath_assets_lp_consulting_woman_steps_content_pc_self_png = "/assets/lp/consulting-woman/steps-content-pc-self.png";
export const staticPath_assets_lp_consulting_woman_steps_content_sp_moneiro_png = "/assets/lp/consulting-woman/steps-content-sp-moneiro.png";
export const staticPath_assets_lp_consulting_woman_steps_content_sp_self_png = "/assets/lp/consulting-woman/steps-content-sp-self.png";
export const staticPath_assets_lp_consulting_woman_steps_tab_bottom_arrow_svg = "/assets/lp/consulting-woman/steps-tab-bottom-arrow.svg";
export const staticPath_assets_lp_consulting_woman_steps_tab_moneiro_color_svg = "/assets/lp/consulting-woman/steps-tab-moneiro-color.svg";
export const staticPath_assets_lp_consulting_woman_steps_tab_moneiro_grayscale_svg = "/assets/lp/consulting-woman/steps-tab-moneiro-grayscale.svg";
export const staticPath_assets_lp_consulting_woman_steps_tab_self_color_svg = "/assets/lp/consulting-woman/steps-tab-self-color.svg";
export const staticPath_assets_lp_consulting_woman_steps_tab_self_grayscale_svg = "/assets/lp/consulting-woman/steps-tab-self-grayscale.svg";
export const staticPath_assets_lp_ideco_hero_base_png = "/assets/lp/ideco/hero-base.png";
export const staticPath_assets_lp_ideco_hero_md_png = "/assets/lp/ideco/hero-md.png";
export const staticPath_assets_lp_ideco_slide01_01_png = "/assets/lp/ideco/slide01_01.png";
export const staticPath_assets_lp_ideco_slide01_02_png = "/assets/lp/ideco/slide01_02.png";
export const staticPath_assets_lp_ideco_slide01_03_png = "/assets/lp/ideco/slide01_03.png";
export const staticPath_assets_lp_ideco_slide02_01_png = "/assets/lp/ideco/slide02_01.png";
export const staticPath_assets_lp_ideco_slide02_02_png = "/assets/lp/ideco/slide02_02.png";
export const staticPath_assets_lp_ideco_slide02_03_png = "/assets/lp/ideco/slide02_03.png";
export const staticPath_assets_lp_ideco2_hero_base_png = "/assets/lp/ideco2/hero-base.png";
export const staticPath_assets_lp_ideco2_hero_md_png = "/assets/lp/ideco2/hero-md.png";
export const staticPath_assets_lp_ideco2_ogp_png = "/assets/lp/ideco2/ogp.png";
export const staticPath_assets_lp_ideco2_recommend_base_png = "/assets/lp/ideco2/recommend-base.png";
export const staticPath_assets_lp_ideco2_recommend_md_png = "/assets/lp/ideco2/recommend-md.png";
export const staticPath_assets_lp_ideco2_slide01_01_png = "/assets/lp/ideco2/slide01_01.png";
export const staticPath_assets_lp_ideco2_slide01_02_png = "/assets/lp/ideco2/slide01_02.png";
export const staticPath_assets_lp_ideco2_slide01_03_png = "/assets/lp/ideco2/slide01_03.png";
export const staticPath_assets_lp_ideco2_slide02_01_png = "/assets/lp/ideco2/slide02_01.png";
export const staticPath_assets_lp_ideco2_slide02_02_png = "/assets/lp/ideco2/slide02_02.png";
export const staticPath_assets_lp_ideco2_slide02_03_png = "/assets/lp/ideco2/slide02_03.png";
export const staticPath_assets_lp_illust_about_nisaitems_svg = "/assets/lp/illust/about_nisaitems.svg";
export const staticPath_assets_lp_illust_about_threefunds_svg = "/assets/lp/illust/about_threefunds.svg";
export const staticPath_assets_lp_money_seminar_academic_cap_svg = "/assets/lp/money-seminar/academic-cap.svg";
export const staticPath_assets_lp_money_seminar_age_png = "/assets/lp/money-seminar/age.png";
export const staticPath_assets_lp_money_seminar_beginner_ideco_nisa100_base_png = "/assets/lp/money-seminar/beginner-ideco-nisa100-base.png";
export const staticPath_assets_lp_money_seminar_beginner_ideco_nisa100_md_png = "/assets/lp/money-seminar/beginner-ideco-nisa100-md.png";
export const staticPath_assets_lp_money_seminar_beginner_ideco_nisa100_sq_png = "/assets/lp/money-seminar/beginner-ideco-nisa100-sq.png";
export const staticPath_assets_lp_money_seminar_beginner_mark_svg = "/assets/lp/money-seminar/beginner-mark.svg";
export const staticPath_assets_lp_money_seminar_family_base_png = "/assets/lp/money-seminar/family-base.png";
export const staticPath_assets_lp_money_seminar_family_md_png = "/assets/lp/money-seminar/family-md.png";
export const staticPath_assets_lp_money_seminar_family_pickup_png = "/assets/lp/money-seminar/family-pickup.png";
export const staticPath_assets_lp_money_seminar_family_sq_png = "/assets/lp/money-seminar/family-sq.png";
export const staticPath_assets_lp_money_seminar_general_30min_base_png = "/assets/lp/money-seminar/general-30min-base.png";
export const staticPath_assets_lp_money_seminar_general_30min_md_png = "/assets/lp/money-seminar/general-30min-md.png";
export const staticPath_assets_lp_money_seminar_general_30min_pickup_png = "/assets/lp/money-seminar/general-30min-pickup.png";
export const staticPath_assets_lp_money_seminar_general_30min_sq_png = "/assets/lp/money-seminar/general-30min-sq.png";
export const staticPath_assets_lp_money_seminar_graphs_svg = "/assets/lp/money-seminar/graphs.svg";
export const staticPath_assets_lp_money_seminar_hero_base_png = "/assets/lp/money-seminar/hero-base.png";
export const staticPath_assets_lp_money_seminar_hero_md_png = "/assets/lp/money-seminar/hero-md.png";
export const staticPath_assets_lp_money_seminar_house_png = "/assets/lp/money-seminar/house.png";
export const staticPath_assets_lp_money_seminar_ideco_base_png = "/assets/lp/money-seminar/ideco-base.png";
export const staticPath_assets_lp_money_seminar_ideco_md_png = "/assets/lp/money-seminar/ideco-md.png";
export const staticPath_assets_lp_money_seminar_ideco_sq_png = "/assets/lp/money-seminar/ideco-sq.png";
export const staticPath_assets_lp_money_seminar_ideco2_base_png = "/assets/lp/money-seminar/ideco2-base.png";
export const staticPath_assets_lp_money_seminar_ideco2_md_png = "/assets/lp/money-seminar/ideco2-md.png";
export const staticPath_assets_lp_money_seminar_mortgage_base_png = "/assets/lp/money-seminar/mortgage-base.png";
export const staticPath_assets_lp_money_seminar_mortgage_md_png = "/assets/lp/money-seminar/mortgage-md.png";
export const staticPath_assets_lp_money_seminar_nisa_basic_base_png = "/assets/lp/money-seminar/nisa-basic-base.png";
export const staticPath_assets_lp_money_seminar_nisa_basic_md_png = "/assets/lp/money-seminar/nisa-basic-md.png";
export const staticPath_assets_lp_money_seminar_nisa_basic_pickup_png = "/assets/lp/money-seminar/nisa-basic-pickup.png";
export const staticPath_assets_lp_money_seminar_nisa_basic2_base_png = "/assets/lp/money-seminar/nisa-basic2-base.png";
export const staticPath_assets_lp_money_seminar_nisa_basic2_md_png = "/assets/lp/money-seminar/nisa-basic2-md.png";
export const staticPath_assets_lp_money_seminar_nisa_basic3_base_png = "/assets/lp/money-seminar/nisa-basic3-base.png";
export const staticPath_assets_lp_money_seminar_nisa_basic3_md_png = "/assets/lp/money-seminar/nisa-basic3-md.png";
export const staticPath_assets_lp_money_seminar_nisa_basic3_pickup_png = "/assets/lp/money-seminar/nisa-basic3-pickup.png";
export const staticPath_assets_lp_money_seminar_nisa_growth_hero_base_png = "/assets/lp/money-seminar/nisa-growth-hero-base.png";
export const staticPath_assets_lp_money_seminar_nisa_growth_hero_md_png = "/assets/lp/money-seminar/nisa-growth-hero-md.png";
export const staticPath_assets_lp_money_seminar_nisa_levelup_base_png = "/assets/lp/money-seminar/nisa-levelup-base.png";
export const staticPath_assets_lp_money_seminar_nisa_levelup_md_png = "/assets/lp/money-seminar/nisa-levelup-md.png";
export const staticPath_assets_lp_money_seminar_nisa_revise_base_png = "/assets/lp/money-seminar/nisa-revise-base.png";
export const staticPath_assets_lp_money_seminar_nisa_revise_md_png = "/assets/lp/money-seminar/nisa-revise-md.png";
export const staticPath_assets_lp_money_seminar_pig_chokinbako_png = "/assets/lp/money-seminar/pig-chokinbako.png";
export const staticPath_assets_lp_money_seminar_seeding_svg = "/assets/lp/money-seminar/seeding.svg";
export const staticPath_assets_lp_money_seminar_solution_50s_base_png = "/assets/lp/money-seminar/solution-50s-base.png";
export const staticPath_assets_lp_money_seminar_solution_50s_md_png = "/assets/lp/money-seminar/solution-50s-md.png";
export const staticPath_assets_lp_mortgage_hero_base_png = "/assets/lp/mortgage/hero-base.png";
export const staticPath_assets_lp_mortgage_hero_md_png = "/assets/lp/mortgage/hero-md.png";
export const staticPath_assets_lp_mortgage_mogecheck_png = "/assets/lp/mortgage/mogecheck.png";
export const staticPath_assets_lp_mortgage_ogp_png = "/assets/lp/mortgage/ogp.png";
export const staticPath_assets_lp_mortgage_recommend_base_png = "/assets/lp/mortgage/recommend-base.png";
export const staticPath_assets_lp_mortgage_recommend_md_png = "/assets/lp/mortgage/recommend-md.png";
export const staticPath_assets_lp_mortgage_slide01_01_png = "/assets/lp/mortgage/slide01_01.png";
export const staticPath_assets_lp_mortgage_slide01_02_png = "/assets/lp/mortgage/slide01_02.png";
export const staticPath_assets_lp_mortgage_slide01_03_png = "/assets/lp/mortgage/slide01_03.png";
export const staticPath_assets_lp_mortgage_slide02_01_png = "/assets/lp/mortgage/slide02_01.png";
export const staticPath_assets_lp_mortgage_slide02_02_png = "/assets/lp/mortgage/slide02_02.png";
export const staticPath_assets_lp_mortgage_slide02_03_png = "/assets/lp/mortgage/slide02_03.png";
export const staticPath_assets_lp_mortgage_slide03_01_png = "/assets/lp/mortgage/slide03_01.png";
export const staticPath_assets_lp_mortgage_slide03_02_png = "/assets/lp/mortgage/slide03_02.png";
export const staticPath_assets_lp_mortgage_slide03_03_png = "/assets/lp/mortgage/slide03_03.png";
export const staticPath_assets_lp_nisa_basic_hero_base_png = "/assets/lp/nisa-basic/hero-base.png";
export const staticPath_assets_lp_nisa_basic_hero_md_png = "/assets/lp/nisa-basic/hero-md.png";
export const staticPath_assets_lp_nisa_basic_ogp_png = "/assets/lp/nisa-basic/ogp.png";
export const staticPath_assets_lp_nisa_basic_slide01_01_png = "/assets/lp/nisa-basic/slide01_01.png";
export const staticPath_assets_lp_nisa_basic_slide01_02_png = "/assets/lp/nisa-basic/slide01_02.png";
export const staticPath_assets_lp_nisa_basic_slide01_03_png = "/assets/lp/nisa-basic/slide01_03.png";
export const staticPath_assets_lp_nisa_basic_slide02_01_png = "/assets/lp/nisa-basic/slide02_01.png";
export const staticPath_assets_lp_nisa_basic_slide02_02_png = "/assets/lp/nisa-basic/slide02_02.png";
export const staticPath_assets_lp_nisa_basic_slide02_03_png = "/assets/lp/nisa-basic/slide02_03.png";
export const staticPath_assets_lp_nisa_basic2_hero_base_png = "/assets/lp/nisa-basic2/hero-base.png";
export const staticPath_assets_lp_nisa_basic2_hero_md_png = "/assets/lp/nisa-basic2/hero-md.png";
export const staticPath_assets_lp_nisa_basic2_ogp_png = "/assets/lp/nisa-basic2/ogp.png";
export const staticPath_assets_lp_nisa_basic2_slide01_01_png = "/assets/lp/nisa-basic2/slide01_01.png";
export const staticPath_assets_lp_nisa_basic2_slide01_02_png = "/assets/lp/nisa-basic2/slide01_02.png";
export const staticPath_assets_lp_nisa_basic2_slide01_03_png = "/assets/lp/nisa-basic2/slide01_03.png";
export const staticPath_assets_lp_nisa_basic2_slide02_01_png = "/assets/lp/nisa-basic2/slide02_01.png";
export const staticPath_assets_lp_nisa_basic2_slide02_02_png = "/assets/lp/nisa-basic2/slide02_02.png";
export const staticPath_assets_lp_nisa_basic2_slide02_03_png = "/assets/lp/nisa-basic2/slide02_03.png";
export const staticPath_assets_lp_nisa_basic3_hero_base_png = "/assets/lp/nisa-basic3/hero-base.png";
export const staticPath_assets_lp_nisa_basic3_hero_md_png = "/assets/lp/nisa-basic3/hero-md.png";
export const staticPath_assets_lp_nisa_basic3_ogp_png = "/assets/lp/nisa-basic3/ogp.png";
export const staticPath_assets_lp_nisa_basic3_recommend_base_png = "/assets/lp/nisa-basic3/recommend-base.png";
export const staticPath_assets_lp_nisa_basic3_recommend_md_png = "/assets/lp/nisa-basic3/recommend-md.png";
export const staticPath_assets_lp_nisa_basic3_slide01_01_png = "/assets/lp/nisa-basic3/slide01_01.png";
export const staticPath_assets_lp_nisa_basic3_slide01_02_png = "/assets/lp/nisa-basic3/slide01_02.png";
export const staticPath_assets_lp_nisa_basic3_slide01_03_png = "/assets/lp/nisa-basic3/slide01_03.png";
export const staticPath_assets_lp_nisa_basic3_slide02_01_png = "/assets/lp/nisa-basic3/slide02_01.png";
export const staticPath_assets_lp_nisa_basic3_slide02_02_png = "/assets/lp/nisa-basic3/slide02_02.png";
export const staticPath_assets_lp_nisa_basic3_slide02_03_png = "/assets/lp/nisa-basic3/slide02_03.png";
export const staticPath_assets_lp_nisa_growth_hero_base_png = "/assets/lp/nisa-growth/hero-base.png";
export const staticPath_assets_lp_nisa_growth_hero_md_png = "/assets/lp/nisa-growth/hero-md.png";
export const staticPath_assets_lp_nisa_growth_ogp_png = "/assets/lp/nisa-growth/ogp.png";
export const staticPath_assets_lp_nisa_growth_recommend_base_png = "/assets/lp/nisa-growth/recommend-base.png";
export const staticPath_assets_lp_nisa_growth_recommend_md_png = "/assets/lp/nisa-growth/recommend-md.png";
export const staticPath_assets_lp_nisa_growth_slide01_01_png = "/assets/lp/nisa-growth/slide01_01.png";
export const staticPath_assets_lp_nisa_growth_slide01_02_png = "/assets/lp/nisa-growth/slide01_02.png";
export const staticPath_assets_lp_nisa_growth_slide01_03_png = "/assets/lp/nisa-growth/slide01_03.png";
export const staticPath_assets_lp_nisa_growth_slide02_01_png = "/assets/lp/nisa-growth/slide02_01.png";
export const staticPath_assets_lp_nisa_growth_slide02_02_png = "/assets/lp/nisa-growth/slide02_02.png";
export const staticPath_assets_lp_nisa_growth_slide02_03_png = "/assets/lp/nisa-growth/slide02_03.png";
export const staticPath_assets_lp_nisa_levelup_hero_base_png = "/assets/lp/nisa-levelup/hero-base.png";
export const staticPath_assets_lp_nisa_levelup_hero_md_png = "/assets/lp/nisa-levelup/hero-md.png";
export const staticPath_assets_lp_nisa_levelup_ogp_png = "/assets/lp/nisa-levelup/ogp.png";
export const staticPath_assets_lp_nisa_levelup_slide01_01_png = "/assets/lp/nisa-levelup/slide01_01.png";
export const staticPath_assets_lp_nisa_levelup_slide01_02_png = "/assets/lp/nisa-levelup/slide01_02.png";
export const staticPath_assets_lp_nisa_levelup_slide01_03_png = "/assets/lp/nisa-levelup/slide01_03.png";
export const staticPath_assets_lp_nisa_levelup_slide02_01_png = "/assets/lp/nisa-levelup/slide02_01.png";
export const staticPath_assets_lp_nisa_levelup_slide02_02_png = "/assets/lp/nisa-levelup/slide02_02.png";
export const staticPath_assets_lp_nisa_levelup_slide02_03_png = "/assets/lp/nisa-levelup/slide02_03.png";
export const staticPath_assets_lp_nisa_revise_hero_base_png = "/assets/lp/nisa-revise/hero-base.png";
export const staticPath_assets_lp_nisa_revise_hero_md_png = "/assets/lp/nisa-revise/hero-md.png";
export const staticPath_assets_lp_nisa_revise_ogp_png = "/assets/lp/nisa-revise/ogp.png";
export const staticPath_assets_lp_nisa_revise_slide01_01_png = "/assets/lp/nisa-revise/slide01_01.png";
export const staticPath_assets_lp_nisa_revise_slide01_02_png = "/assets/lp/nisa-revise/slide01_02.png";
export const staticPath_assets_lp_nisa_revise_slide01_03_png = "/assets/lp/nisa-revise/slide01_03.png";
export const staticPath_assets_lp_nisa_revise_slide02_01_png = "/assets/lp/nisa-revise/slide02_01.png";
export const staticPath_assets_lp_nisa_revise_slide02_02_png = "/assets/lp/nisa-revise/slide02_02.png";
export const staticPath_assets_lp_nisa_revise_slide02_03_png = "/assets/lp/nisa-revise/slide02_03.png";
export const staticPath_assets_lp_nisa_revise_slide03_01_png = "/assets/lp/nisa-revise/slide03_01.png";
export const staticPath_assets_lp_nisa_revise_slide03_02_png = "/assets/lp/nisa-revise/slide03_02.png";
export const staticPath_assets_lp_nisa_revise_slide03_03_png = "/assets/lp/nisa-revise/slide03_03.png";
export const staticPath_assets_lp_shindan_start_financial_products_png = "/assets/lp/shindan-start/financial-products.png";
export const staticPath_assets_lp_shindan_start_moneiro_choice_png = "/assets/lp/shindan-start/moneiro-choice.png";
export const staticPath_assets_lp_smart_hero_base_png = "/assets/lp/smart/hero-base.png";
export const staticPath_assets_lp_smart_hero_md_png = "/assets/lp/smart/hero-md.png";
export const staticPath_assets_lp_smart_ogp_png = "/assets/lp/smart/ogp.png";
export const staticPath_assets_lp_smart_recommend_base_png = "/assets/lp/smart/recommend-base.png";
export const staticPath_assets_lp_smart_recommend_md_png = "/assets/lp/smart/recommend-md.png";
export const staticPath_assets_lp_smart_slide01_01_png = "/assets/lp/smart/slide01_01.png";
export const staticPath_assets_lp_smart_slide01_02_png = "/assets/lp/smart/slide01_02.png";
export const staticPath_assets_lp_smart_slide01_03_png = "/assets/lp/smart/slide01_03.png";
export const staticPath_assets_lp_smart_slide02_01_png = "/assets/lp/smart/slide02_01.png";
export const staticPath_assets_lp_smart_slide02_02_png = "/assets/lp/smart/slide02_02.png";
export const staticPath_assets_lp_smart_slide02_03_png = "/assets/lp/smart/slide02_03.png";
export const staticPath_assets_lp_smart_slide02_04_png = "/assets/lp/smart/slide02_04.png";
export const staticPath_assets_lp_solution_50s_hero_base_png = "/assets/lp/solution-50s/hero-base.png";
export const staticPath_assets_lp_solution_50s_hero_md_png = "/assets/lp/solution-50s/hero-md.png";
export const staticPath_assets_lp_solution_50s_ogp_solution_50s_png = "/assets/lp/solution-50s/ogp_solution-50s.png";
export const staticPath_assets_lp_solution_50s_rcommend_base_png = "/assets/lp/solution-50s/rcommend_base.png";
export const staticPath_assets_lp_solution_50s_rcommend_md_png = "/assets/lp/solution-50s/rcommend_md.png";
export const staticPath_assets_lp_solution_50s_slide01_01_png = "/assets/lp/solution-50s/slide01_01.png";
export const staticPath_assets_lp_solution_50s_slide01_02_png = "/assets/lp/solution-50s/slide01_02.png";
export const staticPath_assets_lp_solution_50s_slide01_03_png = "/assets/lp/solution-50s/slide01_03.png";
export const staticPath_assets_lp_solution_50s_slide02_01_png = "/assets/lp/solution-50s/slide02_01.png";
export const staticPath_assets_lp_solution_50s_slide02_02_png = "/assets/lp/solution-50s/slide02_02.png";
export const staticPath_assets_lp_solution_50s_slide02_03_png = "/assets/lp/solution-50s/slide02_03.png";
export const staticPath_assets_lp_top_a_svg = "/assets/lp/top/a.svg";
export const staticPath_assets_lp_top_after_plan_graph_1_png = "/assets/lp/top/after-plan-graph-1.png";
export const staticPath_assets_lp_top_after_plan_graph_2_png = "/assets/lp/top/after-plan-graph-2.png";
export const staticPath_assets_lp_top_after_plan_graph_3_png = "/assets/lp/top/after-plan-graph-3.png";
export const staticPath_assets_lp_top_arrow_down_png = "/assets/lp/top/arrow-down.png";
export const staticPath_assets_lp_top_arrow_right_png = "/assets/lp/top/arrow-right.png";
export const staticPath_assets_lp_top_before_plan_graph_1_png = "/assets/lp/top/before-plan-graph-1.png";
export const staticPath_assets_lp_top_before_plan_graph_2_png = "/assets/lp/top/before-plan-graph-2.png";
export const staticPath_assets_lp_top_before_plan_graph_3_png = "/assets/lp/top/before-plan-graph-3.png";
export const staticPath_assets_lp_top_logo_amexpo_png = "/assets/lp/top/logo-amexpo.png";
export const staticPath_assets_lp_top_logo_forbes_png = "/assets/lp/top/logo-forbes.png";
export const staticPath_assets_lp_top_logo_hiruobi_png = "/assets/lp/top/logo-hiruobi.png";
export const staticPath_assets_lp_top_logo_jwave_png = "/assets/lp/top/logo-jwave.png";
export const staticPath_assets_lp_top_logo_news_dig_png = "/assets/lp/top/logo-news-dig.png";
export const staticPath_assets_lp_top_logo_newspicks_png = "/assets/lp/top/logo-newspicks.png";
export const staticPath_assets_lp_top_logo_tc_png = "/assets/lp/top/logo-tc.png";
export const staticPath_assets_lp_top_logo_thebridge_png = "/assets/lp/top/logo-thebridge.png";
export const staticPath_assets_lp_top_q_svg = "/assets/lp/top/q.svg";
export const staticPath_assets_lp_top_simulation_3min_svg = "/assets/lp/top/simulation-3min.svg";
export const staticPath_assets_lp_top_star_svg = "/assets/lp/top/star.svg";
export const staticPath_assets_lp_top_steps_content_pc_self_png = "/assets/lp/top/steps-content-pc-self.png";
export const staticPath_assets_lp_top_steps_content_sp_moneiro_png = "/assets/lp/top/steps-content-sp-moneiro.png";
export const staticPath_assets_lp_top_steps_content_sp_self_png = "/assets/lp/top/steps-content-sp-self.png";
export const staticPath_assets_lp_top_steps_tab_bottom_arrow_svg = "/assets/lp/top/steps-tab-bottom-arrow.svg";
export const staticPath_assets_lp_top_steps_tab_moneiro_color_svg = "/assets/lp/top/steps-tab-moneiro-color.svg";
export const staticPath_assets_lp_top_steps_tab_moneiro_grayscale_svg = "/assets/lp/top/steps-tab-moneiro-grayscale.svg";
export const staticPath_assets_lp_top_steps_tab_self_color_svg = "/assets/lp/top/steps-tab-self-color.svg";
export const staticPath_assets_lp_top_steps_tab_self_grayscale_svg = "/assets/lp/top/steps-tab-self-grayscale.svg";
export const staticPath_assets_lp_top_steps_content_pc_moneiro_png = "/assets/lp/top/steps_content_pc_moneiro.png";
export const staticPath_assets_lp_top_storm_png = "/assets/lp/top/storm.png";
export const staticPath_assets_lp_tsumitate_nisa_100_hero_base_png = "/assets/lp/tsumitate-nisa-100/hero-base.png";
export const staticPath_assets_lp_tsumitate_nisa_100_hero_md_png = "/assets/lp/tsumitate-nisa-100/hero-md.png";
export const staticPath_assets_lp_tsumitate_nisa_100_ogp_png = "/assets/lp/tsumitate-nisa-100/ogp.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide01_01_png = "/assets/lp/tsumitate-nisa-100/slide01_01.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide01_02_png = "/assets/lp/tsumitate-nisa-100/slide01_02.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide02_01_png = "/assets/lp/tsumitate-nisa-100/slide02_01.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide02_02_png = "/assets/lp/tsumitate-nisa-100/slide02_02.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide02_03_png = "/assets/lp/tsumitate-nisa-100/slide02_03.png";
export const staticPath_assets_lp_tsumitate_nisa_100_slide02_04_png = "/assets/lp/tsumitate-nisa-100/slide02_04.png";
export const staticPath_assets_lp_woman_common_available_online_png = "/assets/lp/woman-common/available-online.png";
export const staticPath_assets_lp_woman_common_available_online2_png = "/assets/lp/woman-common/available-online2.png";
export const staticPath_assets_lp_woman_common_banner_jpg = "/assets/lp/woman-common/banner.jpg";
export const staticPath_assets_lp_woman_common_chapter_1_png = "/assets/lp/woman-common/chapter-1.png";
export const staticPath_assets_lp_woman_common_chapter_2_png = "/assets/lp/woman-common/chapter-2.png";
export const staticPath_assets_lp_woman_common_chishiki0_png = "/assets/lp/woman-common/chishiki0.png";
export const staticPath_assets_lp_woman_common_edu_simulation_png = "/assets/lp/woman-common/edu_simulation.png";
export const staticPath_assets_lp_woman_common_flow_title_step_01_svg = "/assets/lp/woman-common/flow_title_step_01.svg";
export const staticPath_assets_lp_woman_common_flow_title_step_02_svg = "/assets/lp/woman-common/flow_title_step_02.svg";
export const staticPath_assets_lp_woman_common_flow_title_step_03_svg = "/assets/lp/woman-common/flow_title_step_03.svg";
export const staticPath_assets_lp_woman_common_fund_png = "/assets/lp/woman-common/fund.png";
export const staticPath_assets_lp_woman_common_good_point_title_01_svg = "/assets/lp/woman-common/good_point_title_01.svg";
export const staticPath_assets_lp_woman_common_good_point_title_02_svg = "/assets/lp/woman-common/good_point_title_02.svg";
export const staticPath_assets_lp_woman_common_good_point_title_03_svg = "/assets/lp/woman-common/good_point_title_03.svg";
export const staticPath_assets_lp_woman_common_good_woman_png = "/assets/lp/woman-common/good_woman.png";
export const staticPath_assets_lp_woman_common_graph_woman_png = "/assets/lp/woman-common/graph_woman.png";
export const staticPath_assets_lp_woman_common_ok_woman_png = "/assets/lp/woman-common/ok_woman.png";
export const staticPath_assets_lp_woman_common_q1_png = "/assets/lp/woman-common/q1.png";
export const staticPath_assets_lp_woman_common_q2_png = "/assets/lp/woman-common/q2.png";
export const staticPath_assets_lp_woman_common_q3_png = "/assets/lp/woman-common/q3.png";
export const staticPath_assets_lp_woman_common_q4_png = "/assets/lp/woman-common/q4.png";
export const staticPath_assets_lp_woman_common_question_woman_png = "/assets/lp/woman-common/question-woman.png";
export const staticPath_assets_lp_woman_common_sandglass_woman_png = "/assets/lp/woman-common/sandglass_woman.png";
export const staticPath_assets_lp_woman_common_select_woman_png = "/assets/lp/woman-common/select_woman.png";
export const staticPath_assets_lp_woman_common_simulation_result_png = "/assets/lp/woman-common/simulation_result.png";
export const staticPath_assets_lp_woman_common_three_women_png = "/assets/lp/woman-common/three_women.png";
export const staticPath_assets_lp_woman_common_woman_consulting_participant_age_graph_png = "/assets/lp/woman-common/woman-consulting-participant-age-graph.png";
export const staticPath_assets_lp_woman_common_woman_money_png = "/assets/lp/woman-common/woman_money.png";
export const staticPath_assets_lp_woman_common_writing_woman_png = "/assets/lp/woman-common/writing_woman.png";
export const staticPath_assets_media_ad_banner_3funds_f_png = "/assets/media/ad/banner/3funds-f.png";
export const staticPath_assets_media_ad_banner_family_f_png = "/assets/media/ad/banner/family-f.png";
export const staticPath_assets_media_ad_banner_family_jpg = "/assets/media/ad/banner/family.jpg";
export const staticPath_assets_media_ad_banner_smart_f_png = "/assets/media/ad/banner/smart-f.png";
export const staticPath_assets_media_ad_banner_smart_png = "/assets/media/ad/banner/smart.png";
export const staticPath_assets_media_ad_banner_tn100_f_png = "/assets/media/ad/banner/tn100-f.png";
export const staticPath_assets_media_ad_banner_tn100_png = "/assets/media/ad/banner/tn100.png";
export const staticPath_assets_media_ad_bg_moneiro_png = "/assets/media/ad/bg-moneiro.png";
export const staticPath_assets_media_ad_speaker_png = "/assets/media/ad/speaker.png";
export const staticPath_assets_media_ad_woman_png = "/assets/media/ad/woman.png";
export const staticPath_assets_media_common_hand_with_smartphone_png = "/assets/media/common/hand-with-smartphone.png";
export const staticPath_assets_media_common_ogp_png = "/assets/media/common/ogp.png";
export const staticPath_assets_media_creditcard_svg = "/assets/media/creditcard.svg";
export const staticPath_assets_media_default_header_icon_png = "/assets/media/default/header-icon.png";
export const staticPath_assets_media_entry_book_icon_svg = "/assets/media/entry/book-icon.svg";
export const staticPath_assets_media_entry_caution_icon_svg = "/assets/media/entry/caution-icon.svg";
export const staticPath_assets_media_entry_female_concierge_svg = "/assets/media/entry/female-concierge.svg";
export const staticPath_assets_media_entry_female_user_svg = "/assets/media/entry/female-user.svg";
export const staticPath_assets_media_entry_male_concierge_svg = "/assets/media/entry/male-concierge.svg";
export const staticPath_assets_media_entry_pen_icon_svg = "/assets/media/entry/pen-icon.svg";
export const staticPath_assets_media_feature_entry_arrow_down_svg = "/assets/media/feature-entry/arrow-down.svg";
export const staticPath_assets_media_feature_entry_kv_bg_book_md_png = "/assets/media/feature-entry/kv/bg-book-md.png";
export const staticPath_assets_media_feature_entry_kv_men_png = "/assets/media/feature-entry/kv/men.png";
export const staticPath_assets_media_feature_entry_kv_women_png = "/assets/media/feature-entry/kv/women.png";
export const staticPath_assets_media_snf_logo_dark_png = "/assets/media/snf-logo-dark.png";
export const staticPath_assets_media_snf_logo_png = "/assets/media/snf-logo.png";
export const staticPath_assets_media_social_facebook_svg = "/assets/media/social/facebook.svg";
export const staticPath_assets_media_social_hatebu_svg = "/assets/media/social/hatebu.svg";
export const staticPath_assets_media_social_hatena_svg = "/assets/media/social/hatena.svg";
export const staticPath_assets_media_social_instagram_svg = "/assets/media/social/instagram.svg";
export const staticPath_assets_media_social_line_svg = "/assets/media/social/line.svg";
export const staticPath_assets_media_social_linkedin_svg = "/assets/media/social/linkedin.svg";
export const staticPath_assets_media_social_note_svg = "/assets/media/social/note.svg";
export const staticPath_assets_media_social_twitter_svg = "/assets/media/social/twitter.svg";
export const staticPath_assets_media_woman_ideco_setsuzei_png = "/assets/media/woman-ideco-setsuzei.png";
export const staticPath_assets_omp_members_harada_png = "/assets/omp-members/harada.png";
export const staticPath_assets_omp_members_hujino_jpg = "/assets/omp-members/hujino.jpg";
export const staticPath_assets_omp_members_kamura_profile_jpg = "/assets/omp-members/kamura-profile.jpg";
export const staticPath_assets_omp_members_kamura_png = "/assets/omp-members/kamura.png";
export const staticPath_assets_omp_members_miyazawa_png = "/assets/omp-members/miyazawa.png";
export const staticPath_assets_omp_members_nishimori_jpg = "/assets/omp-members/nishimori.jpg";
export const staticPath_assets_omp_members_oono_png = "/assets/omp-members/oono.png";
export const staticPath_assets_omp_members_sato_jpg = "/assets/omp-members/sato.jpg";
export const staticPath_assets_omp_members_shiozawa_jpg = "/assets/omp-members/shiozawa.jpg";
export const staticPath_assets_omp_members_takahashi_jpg = "/assets/omp-members/takahashi.jpg";
export const staticPath_assets_omp_members_takahashi_profile_jpg = "/assets/omp-members/takahashi_profile.jpg";
export const staticPath_assets_omp_members_ytanaka_png = "/assets/omp-members/ytanaka.png";
export const staticPath_assets_seminar_speakers_1_jpg = "/assets/seminar-speakers/1.jpg";
export const staticPath_assets_seminar_speakers_5_jpg = "/assets/seminar-speakers/5.jpg";
export const staticPath_assets_seminar_speakers_7_jpg = "/assets/seminar-speakers/7.jpg";
export const staticPath_assets_seminar_videos_bgImage_jpg = "/assets/seminar-videos/bgImage.jpg";
export const staticPath_assets_seminar_videos_seminar_speaker_png = "/assets/seminar-videos/seminar-speaker.png";
export const staticPath_assets_seminar_videos_seminar_speaker2x_png = "/assets/seminar-videos/seminar-speaker2x.png";
export const staticPath_assets_seminars_crown_png = "/assets/seminars/crown.png";
export const staticPath_assets_seminars_widgetHeader_png = "/assets/seminars/widgetHeader.png";
export const staticPath_assets_shindan_ogp_ogp_diy_gambler_man_jpg = "/assets/shindan/ogp/ogp-diy_gambler_man.jpg";
export const staticPath_assets_shindan_ogp_ogp_diy_gambler_woman_jpg = "/assets/shindan/ogp/ogp-diy_gambler_woman.jpg";
export const staticPath_assets_shindan_ogp_ogp_diy_steady_man_jpg = "/assets/shindan/ogp/ogp-diy_steady_man.jpg";
export const staticPath_assets_shindan_ogp_ogp_diy_steady_woman_jpg = "/assets/shindan/ogp/ogp-diy_steady_woman.jpg";
export const staticPath_assets_shindan_ogp_ogp_gambler_man_jpg = "/assets/shindan/ogp/ogp-gambler_man.jpg";
export const staticPath_assets_shindan_ogp_ogp_gambler_woman_jpg = "/assets/shindan/ogp/ogp-gambler_woman.jpg";
export const staticPath_assets_shindan_ogp_ogp_steady_man_jpg = "/assets/shindan/ogp/ogp-steady_man.jpg";
export const staticPath_assets_shindan_ogp_ogp_steady_woman_jpg = "/assets/shindan/ogp/ogp-steady_woman.jpg";
export const staticPath_assets_simulation_3min_illust_gg_check_o_png = "/assets/simulation/3min/illust/gg_check-o.png";
export const staticPath_assets_simulation_3min_illust_simulation_3min_actual_machine_image_png = "/assets/simulation/3min/illust/simulation-3min-actual-machine-image.png";
export const staticPath_assets_simulation_3min_lp_about_moneiro_concier_heading_png = "/assets/simulation/3min/lp/about-moneiro-concier-heading.png";
export const staticPath_assets_simulation_3min_lp_hero_base_png = "/assets/simulation/3min/lp/hero-base.png";
export const staticPath_assets_simulation_3min_lp_hero_md_png = "/assets/simulation/3min/lp/hero-md.png";
export const staticPath_assets_simulation_3min_lp_nisa_base_png = "/assets/simulation/3min/lp/nisa-base.png";
export const staticPath_assets_simulation_3min_lp_nisa_md_png = "/assets/simulation/3min/lp/nisa-md.png";
export const staticPath_assets_simulation_3min_lp_ogp_png = "/assets/simulation/3min/lp/ogp.png";
export const staticPath_assets_simulation_3min_message_step1_svg = "/assets/simulation/3min/message/step1.svg";
export const staticPath_assets_simulation_3min_message_step2_svg = "/assets/simulation/3min/message/step2.svg";
export const staticPath_assets_simulation_3min_message_step3_svg = "/assets/simulation/3min/message/step3.svg";
export const staticPath_assets_simulation_3min_message_step4_svg = "/assets/simulation/3min/message/step4.svg";
export const staticPath_assets_simulation_3min_message_step5_svg = "/assets/simulation/3min/message/step5.svg";
export const staticPath_assets_simulation_3min_result_arrow_down_blue_png = "/assets/simulation/3min/result/arrow-down-blue.png";
export const staticPath_assets_simulation_3min_result_arrow_right_blue_png = "/assets/simulation/3min/result/arrow-right-blue.png";
export const staticPath_assets_simulation_3min_result_piggy_bank_svg = "/assets/simulation/3min/result/piggy-bank.svg";
export const staticPath_assets_simulation_3min_result_tsumitate_block_svg = "/assets/simulation/3min/result/tsumitate-block.svg";
export const staticPath_assets_simulation_3min_start_tutorial_modal_step_1_png = "/assets/simulation/3min/start/tutorial-modal/step-1.png";
export const staticPath_assets_simulation_3min_start_tutorial_modal_step_2_png = "/assets/simulation/3min/start/tutorial-modal/step-2.png";
export const staticPath_assets_simulation_nisa_commentary_operation_image_graph_png = "/assets/simulation/nisa/commentary/operation-image-graph.png";
export const staticPath_assets_simulation_nisa_commentary_sp500_crash_png = "/assets/simulation/nisa/commentary/sp500-crash.png";
export const staticPath_assets_simulation_nisa_lp_hero_base_png = "/assets/simulation/nisa/lp/hero-base.png";
export const staticPath_assets_simulation_nisa_lp_hero_md_png = "/assets/simulation/nisa/lp/hero-md.png";
export const staticPath_assets_simulation_nisa_lp_simulation_result_png = "/assets/simulation/nisa/lp/simulation-result.png";
export const staticPath_assets_simulation_nisa_lp_woman_orange_circle_png = "/assets/simulation/nisa/lp/woman-orange-circle.png";
export const staticPath_assets_simulation_nisa_main_visual_concier_glitter_png = "/assets/simulation/nisa/main-visual/concier/glitter.png";
export const staticPath_assets_simulation_nisa_main_visual_concier_smile_png = "/assets/simulation/nisa/main-visual/concier/smile.png";
export const staticPath_assets_simulation_nisa_main_visual_concier_sweat_png = "/assets/simulation/nisa/main-visual/concier/sweat.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_education_fund_preparation_1_png = "/assets/simulation/nisa/main-visual/graph/education-fund-preparation-1.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_education_fund_preparation_2_png = "/assets/simulation/nisa/main-visual/graph/education-fund-preparation-2.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_future_fund_preparation_1_png = "/assets/simulation/nisa/main-visual/graph/future-fund-preparation-1.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_future_fund_preparation_2_png = "/assets/simulation/nisa/main-visual/graph/future-fund-preparation-2.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_future_fund_preparation_3_png = "/assets/simulation/nisa/main-visual/graph/future-fund-preparation-3.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_future_fund_preparation_4_png = "/assets/simulation/nisa/main-visual/graph/future-fund-preparation-4.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_future_fund_preparation_5_png = "/assets/simulation/nisa/main-visual/graph/future-fund-preparation-5.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_no_1_png = "/assets/simulation/nisa/main-visual/graph/no-1.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_no_2_png = "/assets/simulation/nisa/main-visual/graph/no-2.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_no_3_png = "/assets/simulation/nisa/main-visual/graph/no-3.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_pocket_money_earning_1_png = "/assets/simulation/nisa/main-visual/graph/pocket-money-earning-1.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_pocket_money_earning_2_png = "/assets/simulation/nisa/main-visual/graph/pocket-money-earning-2.png";
export const staticPath_assets_simulation_nisa_main_visual_graph_pocket_money_earning_3_png = "/assets/simulation/nisa/main-visual/graph/pocket-money-earning-3.png";
export const staticPath_assets_simulation_nisa_next_action_sbi_key_visual_png = "/assets/simulation/nisa/next-action/sbi/key-visual.png";
export const staticPath_assets_simulation_nisa_next_action_seminar_edu_key_visual_png = "/assets/simulation/nisa/next-action/seminar-edu/key-visual.png";
export const staticPath_assets_simulation_nisa_next_action_simulation_3min_mock_png = "/assets/simulation/nisa/next-action/simulation-3min/mock.png";
export const staticPath_assets_simulation_nisa_next_action_simulation_3min_point_png = "/assets/simulation/nisa/next-action/simulation-3min/point.png";
export const staticPath_assets_simulation_nisa_ogp_png = "/assets/simulation/nisa/ogp.png";
export const staticPath_assets_sns_line_add_friend_png = "/assets/sns/line/add-friend.png";
export const staticPath_assets_sns_line_icon_png = "/assets/sns/line/icon.png";
export const staticPath_assets_top_movie_thumbnail_png = "/assets/top/movie-thumbnail.png";
export const staticPath_assets_top_mv_1_png = "/assets/top/mv-1.png";
export const staticPath_assets_top_mv_2_png = "/assets/top/mv-2.png";
export const staticPath_assets_top_mv_3_png = "/assets/top/mv-3.png";
export const staticPath_assets_top_mv_4_png = "/assets/top/mv-4.png";
export const staticPath_corp_og_jpg = "/corp-og.jpg";
export const staticPath_favicon_svg = "/favicon.svg";
export const staticPath_logo_svg = "/logo.svg";
export const staticPath_nortonsw_7d17b800_6523_0_html = "/nortonsw_7d17b800-6523-0.html";
export const staticPath_og_png = "/og.png";
export const staticPath_opensearch_xml = "/opensearch.xml";
export const staticPath_robots_txt = "/robots.txt";
